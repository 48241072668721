import React, { useRef, useState } from "react";
import Slider from "react-slick";

// slick slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// images
import carouseImg1 from "../../assets/images/carousel-img1.webp";
import carouseImg2 from "../../assets/images/carousel-img2.webp";
import { Link } from "react-router-dom";
import { Config } from "../../Config";

const Header = () => {
  const [slidIndex, setSlidIndex] = useState(0);

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 8000,
    beforeChange: (current, next) => setSlidIndex(next),
  };
  const sliderRef = useRef();

  const handleOnClick = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  return (
    <>
      <div className="position-relative">
        <Slider ref={sliderRef} {...settings}>
          <div className="carousel-slide">
            <img src={carouseImg1} alt="carouseImage" />
            <div className={`slider-content ${slidIndex === 0 && 'customFadeUp'}`}>
              <h2>“Add on” our services to advance your go-to places.</h2>
              <p className="mt-4">
                Install Addon's products to make your spaces better functioning,
                safe and guarded. You rely on us and you will get reliable
                solutions.
              </p>
              <div className="mt-5 pt-2" aria-hidden="true">
                <Link to={`${Config.appUrl}/services`} tabIndex="-1" className="orange-btn px-4 me-md-4 me-3">
                  {" "}
                  our services{" "}
                </Link>
                <Link to={`${Config.appUrl}/contact-us`} tabIndex="-1" className="orange-btn px-4 orange-outline-btn">
                  {" "}
                  contact us{" "}
                </Link>
              </div>
            </div>
          </div>
          <div className="carousel-slide">
            <img src={carouseImg1} alt="carouseImage" />
            <div className={`slider-content ${slidIndex === 1 && 'customFadeUp'} text-center`} >
              <h2>
                Upgrade and safeguard any place you wish to by installing our
                range of products!
              </h2>
              <p className="mt-4">
                We offer you numerous automated systems and products that can
                manage as well as protect any kind of place you want.
              </p>
              <div className="mt-5 pt-2" aria-hidden="true">
                <Link to={`${Config.appUrl}/services`} tabIndex="-1" className="orange-btn px-4 me-md-4 me-3">
                  {" "}
                  our services{" "}
                </Link>
                <Link to={`${Config.appUrl}/contact-us`} tabIndex="-1" className="orange-btn px-4 orange-outline-btn">
                  {" "}
                  contact us{" "}
                </Link>
              </div>
            </div>
          </div>
          <div className="carousel-slide">
            <img src={carouseImg2} alt="carouseImage" />
            <div className={`slider-content ${slidIndex === 2 && 'customFadeUp'} text-end`}>
              <h2>
                Your offices and industries deserve the best expert solutions.
              </h2>
              <p className="mt-4">
                We offer you well made and well tested products for better
                functioning, management and keeping your spaces away from any
                possible mishap.
              </p>
              <div className="mt-5 pt-2" aria-hidden="true">
                <Link to={`${Config.appUrl}/services`} tabIndex="-1" className="orange-btn px-4 me-md-4 me-3">
                  {" "}
                  our services{" "}
                </Link>
                <Link to={`${Config.appUrl}/contact-us`} tabIndex="-1" className="orange-btn px-4 orange-outline-btn">
                  {" "}
                  contact us{" "}
                </Link>
              </div>
            </div>
          </div>
        </Slider>

        <div className="carousel-btns">
          <button
            onClick={(e) => handleOnClick(e.target.value)}
            value={0}
            className={`${slidIndex === 0 && 'currSlide'}`}
            aria-label='slide button 1'>
          </button>

          <button
            onClick={(e) => handleOnClick(e.target.value)}
            value={1}
            className={`${slidIndex === 1 && 'currSlide'}`}
            aria-label='slide button 2'>
          </button>

          <button
            onClick={(e) => handleOnClick(e.target.value)}
            value={2}
            className={`${slidIndex === 2 && 'currSlide'}`}
            aria-label='slide button 3'>
          </button>
        </div>
      </div>
    </>
  );
};

export default Header;
