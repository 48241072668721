import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import { faCheckToSlot, faEarthAmerica, faGear, faHandsClapping } from "@fortawesome/free-solid-svg-icons";

// component
import ProductDetail from '../ProductDetail';

// images 
import CCTVSystemImg from "../../assets/images/products/cctv-system.jpg"
import { Helmet } from 'react-helmet';
import { Config } from '../../Config';

const CCTVSystem = () => {

    useEffect(() => window.scrollTo(0, 0), []);

    const sideTabLinks = [
        {
            title: 'Fire Alarm Systems',
            link: `${Config.appUrl}/service/fire-alarm-systems`
        },
        {
            title: 'Vesda Systems',
            link: `${Config.appUrl}/service/vesda-system`
        },
        {
            title: 'CCTV Systems',
            link: `${Config.appUrl}/service/cctv-systems`
        }
    ]

    const productDetails = {
        name: 'CCTV Systems',
        image: CCTVSystemImg,
        meta_description: "Protect your premises with our advanced IP based CCTV surveillance systems. We are a leading CCTV camera company in Vadodara, Gujarat.",
        paragraphs: [
            "The CCTV systems use high quality video cameras that serve the purpose of surveillance of any place you wish to. The necessity for appropriate security systems have shot up.",

            "They not only help in protecting your spaces but also serve the purpose of keeping a check on what is happening around/in the place.",

            "We offer you security systems specially meant for large scale security that are easy to access and can cover long distances with utmost clarity. Our purpose behind CCTV systems is to make security easy and sufficient."
        ],
        features: [
            {
                title: 'Multiple device connectivity',
                desc: 'We provide an option of multiple connections that keeps the ends open for you in terms of being able to watch the live as well as recorded footage on your smartphones, tablets, and laptops by connecting them to the main system. This helps you in keeping a check on what is happening around even when you are away.',
                icon: faHandsClapping
            },
            {
                title: 'Automation',
                desc: 'We also offer system solutions that are automatic in nature that work on video monitoring and analysis(by the system itself) to constantly analyse and keep a check on the footage coming in from the cameras. This feature is an additional feature that can be added to ensure quick and efficient action in times of emergencies.',
                icon: faCheckToSlot
            },
            {
                title: 'Record',
                desc: 'We provide you security solution with the option of recordable systems that will help you record footages for later views and proofs and not restrict your use to just live view. This additional solution will help you to organise and maintain digital records of the happenings around your space.',
                icon: faGear
            },
            {
                title: 'High definition',
                desc: 'We equip high quality cameras that can capture as well as record high definition footages which enable the user to have good quality, detailed and appropriate record and access to the videos.',
                icon: faEarthAmerica
            }
        ],
        lastParagraph: <p className='paragraph'>We bring to you a varied range of
            <Link to={`${Config.appUrl}/facts-about-of-cctv-camera-that-will-make-you-feel-better`} className='text-orange'> CCTV Systems</Link> from dome and vandal proof cameras to bullet, DVR as well as HD cameras that can be installed according to your requirements.</p>
    }

    return (
        <>
            {/* adding dynamic description */}
            <Helmet>
                <title>Professional CCTV Security Camera Installation Company Gujarat</title>
                <meta name="description" content="Protect your premises with our advanced IP based CCTV surveillance systems. We are a leading CCTV camera company in Vadodara, Gujarat." />

                <meta name='keywords' content={`best cctv camera, best cctv camera company, security camera installation, cctv surveillance system, cctv camera company, ip based cctv camera, ip based cctv surveillance system, cctv supplier, cctv camera for business, cctv dealer, cctv dealer vadodara`} />
            </Helmet>

            <ProductDetail sideTabLinks={sideTabLinks} productDetails={productDetails} />
        </>
    )
}

export default CCTVSystem