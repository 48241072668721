import React, { useEffect } from 'react'
import { faCheckToSlot, faEarthAmerica, faGear, faHandsClapping } from "@fortawesome/free-solid-svg-icons";

// component
import ProductDetail from '../ProductDetail';

// images
import digitalSignageImg from '../../assets/images/products/Digital-Signage.jpg'
import { Helmet } from 'react-helmet';
import { Config } from '../../Config';

const DigitalSignageSolution = () => {

    useEffect(() => window.scrollTo(0, 0), []);

    const sideTabLinks = [
        {
            title: 'Access Control',
            link: `${Config.appUrl}/other-products/access-control`
        },
        {
            title: 'Digital Signage Solution',
            link: `${Config.appUrl}/other-products/digital-signage-solution`
        },
        {
            title: 'PA System',
            link:`${Config.appUrl}/other-products/pa-system`
        }
    ]

    const productDetails = {
        name: 'Digital Signage Solution',
        image: digitalSignageImg,
        paragraphs: [
            "Digital signage solutions help you display your desired content on any type of electronic screens you want. It is an audio-visual broadcast platform that can be used to display any size and form of information.",

            "We offer you Digital signage solutions that enable you to stream your A/V to a large crowd through digital screens that can be connected and broadcasted at any desired place. Our systems are flexible in nature enabling digital display on the size and type of screen you want."
        ],
        features: [
            {
                title: 'Easy to use software',
                desc: 'Digital Signage softwares being used to create, edit and broadcast content by the users, need to be easy to use and manage. We have softwares that are user friendly and can be easily managed and used.',
                icon: faHandsClapping
            },
            {
                title: 'Multi user access',
                desc: 'We provide multi user access to the digital signage network which enables many individual users to have access to the network who can change as well as control the data that is being screened.',
                icon: faCheckToSlot
            },
            {
                title: 'Multi-screen support',
                desc: 'We offer you digital signage solutions that support multiple screen formats like LED TVs, video walls, small screens, etc. This not only makes display of varied content possible but also allows variation in the format and design of the content being published.',
                icon: faGear
            },
            {
                title: 'Network monitoring',
                desc: 'Our digital signage solutions allow you to monitor the signage networks and keep a check on whether the content is being properly displayed or not. It also enables you to appraise the effectiveness and performance of the content being broadcasted.',
                icon: faEarthAmerica
            }
        ],
        lastParagraph: <p className='paragraph'>Digital Signage system fits the current trends and is largely used as an effective medium to reach out to the mass. The solutions we provide are not only effective but also adaptive and collective in nature that encompass numerous crucial features within itself. </p>
    }

    const middleLink = { title: "Other Products", link: `${Config.appUrl}/other-products` }

    return (
        <>
            <Helmet>
                <title>Electronic Digital Signage Solution Supplier Gujarat</title>

                <meta name="description" content="Transform your business with our advanced customized digital signage solution and powerful digital signage software in Vadodara, Gujarat." />

                <meta name='keywords' content='digital signage solution, digital signage software' />
            </Helmet>
            <ProductDetail sideTabLinks={sideTabLinks} productDetails={productDetails} middleLink={middleLink} />
        </>
    )
}

export default DigitalSignageSolution