import React, { useEffect } from 'react'
import { faCheckToSlot, faEarthAmerica, faGear, faHandsClapping } from "@fortawesome/free-solid-svg-icons";

// component
import ProductDetail from '../ProductDetail';

// images
import AccessControlImg from '../../assets/images/products/Access-Control-3.jpg'
import { Helmet } from 'react-helmet';
import { Config } from '../../Config';

const AccessControl = () => {

    useEffect(() => window.scrollTo(0, 0), []);

    const sideTabLinks = [
        {
            title: 'Access Control',
            link: `${Config.appUrl}/other-products/access-control`
        },
        {
            title: 'Digital Signage Solution',
            link: `${Config.appUrl}/other-products/digital-signage-solution`
        },
        {
            title: 'PA System',
            link: `${Config.appUrl}/other-products/pa-system`
        }
    ]

    const productDetails = {
        name: 'Access Control',
        image: AccessControlImg,
        paragraphs: [
            "Access control systems are electronic systems that use door monitoring to regulate access and ensure security and confidentiality of the spaces you wish to protect and supervise. The sole purpose behind installing access control systems is to control and monitor who you want to allow access to.",

            "The systems we bring to you are reliable and trustworthy that will functionally help in managing access to your spaces of any size and lessen or remove the chances of any kind of security and confidentiality risks."
        ],
        features: [
            {
                title: 'Access cards',
                desc: 'We configure access cards along with the access control systems in order to provide an individual access card that is uniquely encoded in a way which keeps a check on who is entering and exiting the protected space.',
                icon: faHandsClapping
            },
            {
                title: 'Door monitoring',
                desc: 'Door monitoring works as a barricade for your spaces. The feature helps in keeping a check on the entry and exit of people and can detect any cases of forced entries or manipulated entries.',
                icon: faCheckToSlot
            },
            {
                title: 'Automatic unlock',
                desc: 'Our access control systems have automated door unlock features that open the card reader controlled doors automatically once the cards are scanned and approved.',
                icon: faGear
            },
            {
                title: 'Easy Updates',
                desc: 'Our systems are specially designed with a feature of upgradation that can allow changes like adding new employees, changing entry terms, removing employee access, door automation changes, etc which can make them more flexible and easy to operate.',
                icon: faEarthAmerica
            }
        ],
        lastParagraph: <p className='paragraph'>Our access control systems are rightly designed and tested before installation to safeguard, regulate and monitor your spaces and avoid any kind of privacy issues.</p>
    }

    const middleLink = { title: "Other Products", link: `${Config.appUrl}/other-products` }

    return (
        <>
            <Helmet>
                <title>Secure Your Space with Best Access Control Security Systems Gujarat</title>

                <meta name="description" content="Enhance security with the best electronic access control systems. Explore our access control solutions for robust access control security available in Vadodara, Gujarat." />
                <meta name='keywords' content='access control systems, access control security, best access control systems'/>
            </Helmet>
            <ProductDetail sideTabLinks={sideTabLinks} productDetails={productDetails} middleLink={middleLink} />
        </>
    )
}

export default AccessControl