import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

// images
import headingImg from "../../assets/images/heading-img.png";
import locationLogo from "../../assets/images/location.png";
import phoneLogo from "../../assets/images/phone.png";
import emailLogo from "../../assets/images/message.png";
import axios from "axios";
import swal from "sweetalert";
import loaderImg from "../../assets/images/loader.gif";
import { Helmet } from "react-helmet";
import { Config } from "../../Config";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  // state for form data
  const [contactData, setContactData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  // state for errors messages
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  useEffect(() => window.scrollTo(0, 1), []);

  // form validations
  const validateForm = () => {
    const newErrors = {};

    if (!contactData.firstName) {
      newErrors.firstName = "First name is required";
    }

    if (!contactData.lastName) {
      newErrors.lastName = "Last name is required";
    }

    if (!contactData.email) {
      newErrors.email = "Email is required";
    } else if (
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(contactData.email)
    ) {
      newErrors.email = "Invalid email format";
    }

    if (!contactData.phone) {
      newErrors.phone = "Phone number is required";
    } else if (contactData.phone.length > 10 || contactData.phone.length < 10) {
      newErrors.phone = "Phone number must be of 10 digits";
    }

    if (!contactData.message) {
      newErrors.message = "Your Query is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // sending clients query
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);

      try {
        const formData = new FormData();

        formData.append("fname", contactData.firstName);
        formData.append("lname", contactData.lastName);
        formData.append("email", contactData.email);
        formData.append("phone", contactData.phone);
        formData.append("help", contactData.message);

        await axios.post(
         `${Config.apiUrl}/contact`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        swal(
          "Message Sent",
          "Your Query has been sent successfully",
          "success"
        );
        setLoading(false);
        setContactData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          message: "",
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
        swal("Something Went Wrong!", "", "error");
      }
    }
  };

  // set Form Data
  const handleChange = (e) => {
    setContactData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <Helmet>
        <title>Contact Us for VESDA Services in Gujarat | Addon Techno</title>

        <meta
          name="description"
          content="Get in touch with us for all your security needs. Our experts are ready to assist you with cutting-edge solutions, reliable support, and customer service."
        />
      </Helmet>

      {/* loading */}
      <div className={`loader ${loading ? "d-flex" : "d-none"}`}>
        <img src={loaderImg} alt="loaderImage" />
      </div>

      <section className="page-header py-5">
        <div className="container-box py-4">
          <div className="text-white">
            <h1 className="fw-bold display-5">Contact Us</h1>
            <p className="mt-4 header-path-link">
              <Link to={`${Config.appUrl}/`} className="text-orange">
                {" "}
                Home
              </Link>
              <FontAwesomeIcon
                icon={faArrowRight}
                className="social-icon me-3"
              ></FontAwesomeIcon>
              Contact Us
            </p>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container-box pb-5">
          <div className="row">
            <div className="col-md-4 text-center px-4 mt-5">
              <img
                src={locationLogo}
                className="contact-svg-img"
                alt="contactIcon"
              />
              <div className="mt-4">
                <h5 className="text-dark-blue fw-bold">
                  Company Headquarters :
                </h5>
                <p className="mb-2">
                  GF-12, Race Course Tower, Race Course, Vadodara, Gujarat,
                  390007
                </p>
              </div>
            </div>

            <div className="col-md-4 text-center mt-5">
              <img
                src={phoneLogo}
                className="contact-svg-img"
                alt="contactIcon"
              />
              <div className="mt-4">
                <h5 className="text-dark-blue fw-bold">Contact Number :</h5>
                <Link to="tel:+91-98244-18222" className="text-dark d-block">
                  + (91) 98244-18222
                </Link>
                <span>Don't hesitate to contact us!</span>
              </div>
            </div>

            <div className="col-md-4 text-center mt-5">
              <img
                src={emailLogo}
                className="contact-svg-img"
                alt="contactIcon"
              />
              <div className="mt-4">
                <h5 className="text-dark-blue fw-bold">Email Address :</h5>
                <Link to="mailto:sales@addontechno.com" className="text-dark">
                  sales@addontechno.com
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* contact us form */}
      <section className="contact-form py-5">
        <div className="container-box py-5">
          <div className="row">
            <div className="col-xxl-5 col-xl-6 col-md-7 position-relative">
              {/* social icons */}
              <div className="d-flex justify-content-center pt-4 social-media-icons">
                <a
                  href="https://www.twitter.com/addontechno"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="circle-icon mx-4">
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className="text-dark-blue"
                    ></FontAwesomeIcon>
                  </span>
                </a>

                <a
                  href="https://www.facebook.com/addontechnology"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="circle-icon mx-4">
                    <FontAwesomeIcon
                      icon={faFacebookF}
                      className="text-dark-blue"
                    ></FontAwesomeIcon>
                  </span>
                </a>

                <a
                  href="https://www.linkedin.com/company/addontechnology"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="circle-icon mx-4">
                    <FontAwesomeIcon
                      icon={faLinkedinIn}
                      className="text-dark-blue"
                    ></FontAwesomeIcon>
                  </span>
                </a>
              </div>

              <div className="top-heading mb-5">
                <img src={headingImg} alt="title icon" />
                <div className="ms-3">
                  <span>Contact Us</span>
                  <h3 className="text-white">Feel free to ask anything!</h3>
                </div>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-6 mb-4">
                    <input
                      type="text"
                      name="firstName"
                      value={contactData.firstName}
                      onChange={handleChange}
                      placeholder="First Name*"
                      className="contact-field"
                    />

                    {errors.firstName && (
                      <span className="text-danger">{errors.firstName}</span>
                    )}
                  </div>
                  <div className="col-sm-6 mb-4">
                    <input
                      type="text"
                      name="lastName"
                      value={contactData.lastName}
                      onChange={handleChange}
                      placeholder="Last Name"
                      className="contact-field"
                    />

                    {errors.lastName && (
                      <span className="text-danger">{errors.lastName}</span>
                    )}
                  </div>

                  <div className="col-sm-6 mb-4">
                    <input
                      type="text"
                      name="email"
                      value={contactData.email}
                      onChange={handleChange}
                      placeholder="Email Address"
                      className="contact-field"
                    />

                    {errors.email && (
                      <span className="text-danger">{errors.email}</span>
                    )}
                  </div>
                  <div className="col-sm-6 mb-4">
                    <input
                      type="number"
                      name="phone"
                      value={contactData.phone}
                      onChange={handleChange}
                      placeholder="Phone"
                      className="contact-field"
                    />

                    {errors.phone && (
                      <span className="text-danger">{errors.phone}</span>
                    )}
                  </div>

                  <div className="col-12 mb-4">
                    <textarea
                      className="contact-field"
                      name="message"
                      value={contactData.message}
                      onChange={handleChange}
                      placeholder="How can we help?"
                      rows="7"
                    ></textarea>

                    {errors.message && (
                      <span className="text-danger">{errors.message}</span>
                    )}
                  </div>

                  <div className="col-12">
                    <button type="submit" className="orange-btn px-4">
                      {" "}
                      send message{" "}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* map  */}
      <section>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17557.53591299293!2d73.1440232746314!3d22.31518726885847!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc8bd49ed0b41%3A0x7e54f2cd32256db!2sAddon%20Technology!5e0!3m2!1sen!2sin!4v1691152223933!5m2!1sen!2sin"
          className="w-100 border"
          height="550"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="company location map"
        ></iframe>
      </section>
    </>
  );
};

export default ContactUs;
