import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import { faCheckToSlot, faEarthAmerica, faGear, faHandsClapping } from "@fortawesome/free-solid-svg-icons";

// component
import ProductDetail from '../ProductDetail';

// images 
import FireAlarmSystemImg from "../../assets/images/products/fire-alarm-system.jpg"
import { Helmet } from 'react-helmet';
import { Config } from '../../Config';

const FireAlarmSystem = () => {

    useEffect(() => window.scrollTo(0, 0), []);

    const sideTabLinks = [
        {
            title: 'Fire Alarm Systems',
            link: `${Config.appUrl}/service/fire-alarm-systems`
        },
        {
            title: 'Vesda Systems',
            link: `${Config.appUrl}/service/vesda-system`
        },
        {
            title: 'CCTV Systems',
            link: `${Config.appUrl}/service/cctv-systems`
        }
    ]

    const productDetails = {
        name: 'Fire Alarm Systems',
        image: FireAlarmSystemImg,
        meta_description: "Ensure comprehensive fire security with our reliable fire alarm solutions. We provide superior fire suppression systems and services in Vadodara, Gujarat.",
        paragraphs: [
            "Fire Alarm system is a well-built system that connects various fire related components with itself including alarms, fire controllers, central unit as well as other fire security systems. The sole purpose behind fire alarm is to warn people about fire and provide preventive measures immediately to cure the problem.",

            "Addon offers you a diverse range of fire alarm systems to choose from. According to the type of business or space you wish to secure, we can provide you with the most appropriate and suitable kind of system that will match to your requirements."
        ],
        features: [
            {
                title: 'System modes',
                desc: 'We provide solutions with an adjustable feature of auto and manual settings which let you make changes according to the need of the hour. The auto service helps keep a check on errors and requires very less human interface while the manual mode helps you set the system as per the requirements.',
                icon: faHandsClapping
            },
            {
                title: 'Connections',
                desc: 'Our fire alarm system provides an appropriate structure of connections and network that enable connecting multiple devices together. They also manage and control the functioning of the various devices connected to ensure that the space is fire-resistant.',
                icon: faCheckToSlot
            },
            {
                title: 'Multi user access',
                desc: 'We provide fire solutions with an option of multiple connectivity. This feature lets you adjust and access the display of the system on multiple screens so that the origin of fire can be known through the alarms on the smartphones connected to the main system.',
                icon: faGear
            },
            {
                title: 'Emergencies',
                desc: 'We back the system up with fire-resistant system that avoids and limits the spread of fire if in case any such incident occurs. This makes your space double secured by having both quick warning as well as a fire control system during fire emergencies.',
                icon: faEarthAmerica
            }
        ],
        lastParagraph: <p className='paragraph'>Our <Link to={`${Config.appUrl}/do-you-know-right-fire-alarm-system-can-save-your-business`} className='text-orange'>Fire Alarm Systems</Link> are well developed by professionals with high quality sensors and alarms that can notify people beforehand, quickly and immediately.</p>
    }

    return (
        <>
            {/* adding dynamic description */}
            <Helmet>
                <title>Fire Alarm Solutions Provider and Dealer Gujarat | Addon Techno</title>
                <meta name="description" content="Ensure comprehensive fire security with our reliable fire alarm solutions. We provide superior fire suppression systems and services in Vadodara, Gujarat." />

                <meta name='keywords' content={`fire alarm dealers near me, fire alarm for business, fire alarm detector,fire alarm solution provider, fire alarm solution provider Gujarat, Fire Suppression System supplier, fire suppression system, fire suppression services, fire detection system, fire security system `} />
            </Helmet>

            <ProductDetail sideTabLinks={sideTabLinks} productDetails={productDetails} />
        </>
    )
}

export default FireAlarmSystem