import React, { useEffect, useState } from 'react'
import loaderImg from "../../assets/images/loader.gif"
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Config } from '../../Config';

const Terms = () => {
    const [loading, setLoading] = useState(false);
    useEffect(() => window.scrollTo(0, 1), []);
  return (
    <>
        {/* loading */}
        <div className={`loader ${loading ? 'd-flex' : 'd-none'}`}>
                <img src={loaderImg} alt="loaderImage" />
            </div>
        
            <section className="page-header py-5">
                <div className="container-box py-4">
                    <div className='text-white'>
                        <h1 className='fw-bold display-5'>Terms & Conditions</h1>
                        <p className='mt-4 header-path-link'>
                            <Link to={`${Config.appUrl}/`} className="text-orange"> Home</Link>
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                className='social-icon me-3'
                            ></FontAwesomeIcon>
                            Terms And Conditions
                        </p>
                    </div>
                </div>
            </section>

            <section >
        <div className="container-box-terms py-md-5 owner-bg top-heading-terms">
            <h3 className='display-5 '>Terms & Conditions</h3>

            <span className='mt-3'>Welcome to Addon Technology!</span>
            <p>Before you dive into our website <a href='https://www.addontechno.com/'>https://www.addontechno.com/</a>, let's go over some important details together. By using our site, you're agreeing to the following terms and conditions. Don't hesitate to reach out if you have any questions or just want to say hi!</p>

            <span>Your Privacy is Important</span>
            <p>Your privacy matters to us. We use cookies to make your browsing experience better. By continuing to use our site, you're giving us the green light to use cookies in line with our Privacy Policy. We promise to only use your data to improve our services and make your experience even smoother.</p>

            <span>Respect for Intellectual Property</span>
            <p>Everything you see on our site belongs to Addon Technology or our awesome licensors. Feel free to explore and use our content for personal purposes, but please don't go ahead and start selling or sharing it without giving us a heads up.</p>

            <span>Let's Link Up!</span>
            <p>We love it when people want to share our content! If you'd like to link to our site, just shoot us an email first. We'll get back to you faster than you can say "hyperlink"!</p>

            <span>We're Only Human</span>
            <p>While we strive for perfection, we're only human. Sometimes mistakes happen, and information might not always be up to date. If you spot something that doesn't look right, give us a shout. We're all ears!</p>

            <span>Changes Happen</span>
            <p>Just like life, our terms and conditions can change. By using our site, you agree to roll with the punches and abide by the latest version of our terms. We'll do our best to keep you in the loop about any big changes.</p>

            <span>Disclaimer - Let's Keep it Real</span>
            <p>We're all about transparency here. While we do our best to keep our site running smoothly, we can't promise it'll be error-free all the time. By using our site, you acknowledge that we're not responsible for any hiccups along the way</p>

            <span>We're Here for You</span>
            <p>If you have any questions, concerns, or just want to chat, we're here for you. Drop us a line anytime. Seriously, we love hearing from you!</p>
            <p>Thanks for choosing Addon Technology. Now, let's make some digital magic together!</p>

        </div>
        </section>



    </>
  )
}

export default Terms
