import './App.css';
import './assets/css/Home.css'
// bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// router
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PageNotFound from './components/Home/PageNotFound';
import Home from './components/Home/Home';
import Navbar from './components/Home/Navbar';
import Footer from './components/Home/Footer';

// components
import OtherProducts from './components/OtherProducts/OtherProducts';
import AccessControl from './components/OtherProducts/AccessControl';
import DigitalSignageSolution from './components/OtherProducts/DigitalSignageSolution';
import PASystem from './components/OtherProducts/PASystem';

import Services from './components/Services/Services';
import AboutUs from './components/About Us/AboutUs';
import ContactUs from './components/Contact Us/ContactUs';

import Blogs from './components/Blogs/Blogs';
import BlogDetails from './components/Blogs/BlogDetails';
import Tags from './components/Blogs/Tags';

import VesdaSystem from './components/Vesda System/VesdaSystem';
import FireAlarmSystem from './components/Fire Alarm Systems/FireAlarmSystem';
import CCTVSystem from './components/CCTV Systems/CCTVSystem';
import MehulGoswami from './components/Blogs/MehulGoswami';
import SearchBlog from './components/Blogs/SearchBlog';
import Terms from './components/Policies/Terms';
import PrivacyPolicy from './components/Policies/PrivacyPolicy';
import { Config } from './Config';

function App() {

  return (
    <>
      <BrowserRouter>
        <Navbar />

        <Routes >
          <Route path="*" element={<PageNotFound />} />
          <Route exact path={`${Config.appUrl}/`} element={<Home />} />

          {/* other products */}
          <Route path={`${Config.appUrl}/other-products`} element={<OtherProducts />} />
          <Route path={`${Config.appUrl}/other-products/access-control`} element={<AccessControl />} />
          <Route path={`${Config.appUrl}/other-products/digital-signage-solution`} element={<DigitalSignageSolution />} />
          <Route path={`${Config.appUrl}/other-products/pa-system`} element={<PASystem />} />

          <Route path={`${Config.appUrl}/services`} element={<Services />} />
          <Route path={`${Config.appUrl}/about-us`} element={<AboutUs />} />
          <Route path={`${Config.appUrl}/contact-us`} element={<ContactUs />} />
          <Route path={`${Config.appUrl}/terms`} element={<Terms />} />
          <Route path={`${Config.appUrl}/privacy-policy`} element={<PrivacyPolicy />} />

          <Route path={`${Config.appUrl}/blog`} element={<Blogs />} />
          <Route path={`${Config.appUrl}/:slug`} element={<BlogDetails />} />
          <Route path={`${Config.appUrl}/tag/:slug`} element={<Tags />} />
          <Route path={`${Config.appUrl}/author/mehul-goswami`} element={<MehulGoswami />} />
          <Route path={`${Config.appUrl}/search/:searchStr`} element={<SearchBlog />} />

          {/* services  */}
          <Route path={`${Config.appUrl}/service/vesda-system`} element={<VesdaSystem />} />
          <Route path={`${Config.appUrl}/service/fire-alarm-systems`} element={<FireAlarmSystem />} />
          <Route path={`${Config.appUrl}/service/cctv-systems`} element={<CCTVSystem />} />

        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
