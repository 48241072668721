import React, { useEffect, useState } from "react";
import Header from "./Header";
import Slider from "react-slick";
import axios from "axios";
import loaderImg from '../../assets/images/loader.gif'

// animation package
import AOS from 'aos';
import 'aos/dist/aos.css';

// icons
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faEnvelopeSquare,
  faGear,
  faLink,
  faPlane,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

// images
import aboutImg from "../../assets/images/about-section-img.webp";
import headingImg from "../../assets/images/heading-img.png";
import mehulImg from "../../assets/images/mehul_goswami_img.webp";
import signature from "../../assets/images/mehul_signature.png";
import product1 from "../../assets/images/products/fire-alarm-system.jpg";
import product2 from "../../assets/images/products/vesda-system.jpg";
import product3 from "../../assets/images/products/cctv-system.jpg";
import whyChooseUs from "../../assets/images/why-choose-us.webp";

// clients img
import client1 from "../../assets/images/partners/partner1.webp";
import client2 from "../../assets/images/partners/partner2.webp";
import client3 from "../../assets/images/partners/partner3.webp";
import client4 from "../../assets/images/partners/partner4.webp";
import client5 from "../../assets/images/partners/partner5.webp";
import client6 from "../../assets/images/partners/partner6.webp";
import client7 from "../../assets/images/partners/partner7.webp";
import client8 from "../../assets/images/partners/partner8.webp";
import client9 from "../../assets/images/partners/partner9.webp";
import swal from "sweetalert";
import { Helmet } from "react-helmet";
import { Config } from "../../Config";

const Home = () => {

  const [loading, setLoading] = useState(false);
  // state for form data
  const [quoteData, setQuoteData] = useState({
    name: '',
    email: '',
    system: 'Vesda system',
    message: '',
  });
  // state for errors messages
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    system: '',
    message: '',
  });

  // state for dynamic blogs
  const [blogs, setBlogs] = useState([]);

  // Integrating api for fetching blogs
  const getBlogs = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${Config.apiUrl}/mainpage`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const updatedBlogs = response.data.blog.map((blog) => {
        blog.totalComments = response.data.comment_count.filter((bcomment) => blog.id === bcomment.blog_id).length;
        return blog
      })
      setBlogs(updatedBlogs)

      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getBlogs();
  }, [])

  // animations
  useEffect(() => {
    AOS.init({
      offset: 100, // offset (in px) from the original trigger point
      duration: 800, // values from 0 to 3000, with step 50ms
      easing: 'ease-out',
      once: true,
    });
  }, [])

  document.title = "Top Fire Alarm System SITC Supplier and Dealer Gujarat";

  // ======================== QUOTE FORM ==================================
  // form validations for getQuote
  const validateForm = () => {
    const newErrors = {};

    if (!quoteData.name) {
      newErrors.name = 'First name is required';
    }

    if (!quoteData.email) {
      newErrors.email = 'Email is required';
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(quoteData.email)) {
      newErrors.email = 'Invalid email format';
    }

    if (!quoteData.message) {
      newErrors.message = 'Your Query is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // set Quote Form Data
  const handleChange = (e) => {
    setQuoteData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  // get quote form 
  const handleQuoteSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      setLoading(true)
      try {
        const formData = new FormData();

        formData.append('name', quoteData.name);
        formData.append('email', quoteData.email);
        formData.append('systems', quoteData.system);
        formData.append('requirement', quoteData.message);

        await axios.post(`${Config.apiUrl}/request-quote`, formData, {
          headers: {
            'Content-Type': 'application/json',
          }
        })

        swal("Quote Sent", "Your Quote Request has been sent successfully", "success");
        setLoading(false)
        setQuoteData(
          {
            name: '',
            email: '',
            system: 'Vesda system',
            message: '',
          }
        )
      } catch (error) {
        console.log(error)
        setLoading(false)
        swal("Something Went Wrong!", "", "error");
      }
    }
  }

  // --------------------------------End------------------------------------------

  const products = [
    {
      name: "Fire Alarm Systems",
      desc: "We have special fire alarm systems with high quality sensors and alarms that detect and caution people at the earliest stages of any fire related tendencies.",
      icon: faPlane,
      image: product1,
      alt: "conventional fire alarm",
      link: `${Config.appUrl}/service/fire-alarm-systems`
    },
    {
      name: "Vesda Systems",
      desc: "We have been known for our well-established and well-maintained VESDA systems that provide the quickest smoke detection and monitoring service.",
      icon: faGear,
      image: product2,
      alt: "vesda fire system",
      link: `${Config.appUrl}/service/vesda-system`
    },
    {
      name: "CCTV Systems",
      desc: "We design CCTV systems that enable recording footages along with offering good quality video-audio clips to keep an eye on the whereabouts of any desired place.",
      icon: faEnvelopeSquare,
      image: product3,
      alt: "cctv system",
      link: `${Config.appUrl}/service/cctv-systems`
    },
  ];

  const clients = [
    client1,
    client2,
    client3,
    client4,
    client5,
    client6,
    client7,
    client8,
    client9
  ];

  const testimonialSettings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 2,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 908,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const blogsSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    swipeToSlide: true,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 6000,
    responsive: [
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const clientSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2000,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 908,
        settings: {
          arrows: false,
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <meta name="description" content="Looking for a trusted fire alarm system company in Vadodara, Gujarat? Get expert fire alarm installation services from a reliable supplier and dealer." />
        <meta name="keywords" content="fire alarm system, fire alarm system installation, fire alarm system company, fire alarm installation, fire alarm supplier, fire alarm dealer, fire alarm company, fire system dealer " />
      </Helmet>

      {/* loading */}
      <div className={`loader ${loading ? 'd-flex' : 'd-none'}`}>
        <img src={loaderImg} alt="loaderImage" />
      </div>

      <Header />
      {/* who we are - section */}
      <section className="py-5">
        <div className="container-box py-md-5">
          <div className="row flex-lg-row flex-column justify-content-center align-items-xl-center">
            <div className="col-lg-6 text-center" data-aos="fade-right">
              <div className="image-container">
                <img src={aboutImg} alt="aboutImg" className="w-100 h-100" />
                <div className="overlay"></div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="top-heading mt-lg-0 mt-5" data-aos="fade-up" data-aos-offset="10">
                <img src={headingImg} alt="title icon" />
                <div className="ms-3">
                  <span>Who we are</span>
                  <h3>Addon Technology</h3>
                </div>
              </div>

              <div>
                <p className="paragraph mt-35">
                  Addon technology is a solutions providing company (SITC
                  expert) with an experience of more than 15 years with
                  technical expertise and high end professionals. Established in
                  Vadodara, Gujarat, India, we have clients from all across the
                  nation whom we have successfully helped in installing high
                  ended products and systems.
                </p>
                <p className="paragraph mt-35">
                  We extend to you a wide range of management, security and
                  surveillance products stretching from CCTV cameras and VESDA
                  systems to gate automation and fire alarms. We bring to you
                  various kinds of systems in one plate that can function both
                  as a helping hand as well as a barricade to your business/
                  industrial spaces. We assure to set in place the best
                  equipments that are completely trustworthy and dependable.
                </p>
                <p className="paragraph mt-35">
                  Our systems are well developed and appropriately tested before
                  installation in order to get the best results. We have teams
                  of experienced professionals who specialize in different
                  fields, be it system designing, installation, maintenance,
                  management or testing and production.
                </p>
                <p className="paragraph mt-35">
                  Along with the kind of systems and products we offer, we are
                  well known for the service we provide that not only exists
                  until the installation of products, but also later for support
                  and commitment towards our customers. We also offer you proper
                  and good quality maintenance service that will keep a check on
                  all the installed systems and ensure their proper functioning.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* inspiring section */}
      <section className="inspiring-section owner-bg py-5 position-relative">
        <div className="container-box">
          <div className="row py-md-5 justify-content-between">
            <div className="col-lg-5 ">
              <div className="top-heading">
                <img src={headingImg} alt="title icon" />
                <div className="ms-3">
                  <span>Dream Big</span>
                  <h3>Inspiring Solutions</h3>
                </div>
              </div>

              <div>
                <p className="paragraph mt-35">
                  “Being the initiator of the company, the purpose upon which I
                  run my company is not profit making, rather “customer welfare”
                  in order to keep them happy and provide them with the best
                  solutions they are looking for. Our clients are our
                  responsibility, and we from our end ensure quality service and
                  high end products that can make your business safer and better.
                  “
                </p>
              </div>

              <div className="mt-5 pt-4">
                <img src={signature} alt="signature" className="img-fluid" />
                <h5 className="inspiring-author mt-4">Mehul Goswami</h5>
                <h6 className="inspiring-author-role">CEO &amp; Founder</h6>
              </div>
            </div>
            <div className="col-lg-6 mt-lg-0 mt-5">
              <img src={mehulImg} alt="ownerImage" className="w-100 rounded ownerImage" />
            </div>
          </div>
        </div>
      </section>

      {/* services section */}
      <section className="py-5">
        <div className="container-box py-md-5">
          <div className="row align-items-center position-relative">
            <div className="col-md-7">
              <div className="top-heading mb-md-0 mb-5">
                <img src={headingImg} alt="title icon" />
                <div className="ms-3">
                  <span>Services</span>
                  <h3>We Offer</h3>
                </div>
              </div>
            </div>
            <div className="col-md-5 text-md-end">
              <Link to={`${Config.appUrl}/other-products`} className="orange-btn px-4 " >
                {" "}
                other products{" "}
              </Link>
            </div>
          </div>
          <div className="row ">
            {products.map((product, index) => {
              return (
                <div key={index} className="col-sm-6 col-lg-4 px-3 mt-5">
                  <Link to={product.link}>
                    <div className="product-card">
                      <div className="product-img-box border">
                        <img src={product.image} alt={product.alt} />
                        <div className="shape"></div>
                        <FontAwesomeIcon
                          icon={product.icon}
                          className="product-icon"
                        ></FontAwesomeIcon>

                        {/* hover box */}
                        <div className="hover-effect-box">
                          <div>
                            <FontAwesomeIcon
                              icon={faLink}
                              className="text-orange link-icon"
                            ></FontAwesomeIcon>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 product-content">
                        <h4 className="text-dark-blue"> {product.name} </h4>

                        <p className="paragraph mt-3">{product.desc}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* why choose us */}
      <section className="py-5 bg-light-gray">
        <div className="container-box py-md-5">
          <div className="row align-items-center why-choose-us">
            <div className="col-md-6">
              <div className="top-heading" data-aos="fade-up" data-aos-offset="50">
                <img src={headingImg} alt="title icon" />
                <div className="ms-3">
                  <span>Everything has a reason!</span>
                  <h3>Why choose us?</h3>
                </div>
              </div>

              <div className="mt-35">
                <div data-aos="fade-up" data-aos-offset="100">
                  <p className="paragraph">
                    Before appointing anyone, you must know what benefits you are
                    going to enjoy. We understand our client’s requirements and
                    provide them with products that give them the desired and up
                    to the mark results.
                  </p>

                  <ul className="technical-solutions ps-4">
                    <li>Our quality gives justice to the money invested.</li>
                    <li>High efficiency and easy control.</li>
                    <li>
                      We provide with both turnkey (ready) as well as build to
                      order (customised) systems.
                    </li>
                    <li>
                      Expertise in SITC ( Supply, Installation, Testing and
                      Commissioning)
                    </li>
                    <li>
                      Management and maintenance of the installed system by the
                      team.
                    </li>
                    <li>Good experience and qualified team.</li>
                    <li>Large customer base and positive response.</li>
                  </ul>
                </div>

                <div className="mt-5" data-aos="fade-up" data-aos-offset="10">
                  <Link to={`${Config.appUrl}/contact-us`} className="orange-btn px-4 ">
                    {" "}
                    contact us{" "}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-md-block d-none">
              <img src={whyChooseUs} alt="cctv system installation" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials */}
      <section className="py-5 testimonial-bg">
        <div className="container-box py-md-5">
          <div className="row">
            <div className="col-md-8">
              <div className="top-heading">
                <img src={headingImg} alt="title icon" />
                <div className="ms-3">
                  <span>Testimonials</span>
                  <h3 className="text-white">Our client’s views on Addon</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="text-white mt-5">
            <Slider {...testimonialSettings}>
              <div className="p-1 px-md-3">
                <div className="testimonial-slide">
                  <h3 className="mb-4 text-orange">Impressive</h3>

                  <p className="text-light">
                    We have been extremely impressed with Addon Technology. In
                    highly specialized security System skills, they quickly
                    seized on the opportunities and issues in our business and
                    put together a comprehensive plan. We are very pleased with
                    their results!
                  </p>

                  <h5>UPG - WAGHODIA, GUJARAT</h5>
                  {[...Array(5)].map((_, i) => {
                    return (
                      <FontAwesomeIcon
                        icon={faStar}
                        className="text-orange star-icon"
                        key={i}
                      ></FontAwesomeIcon>
                    );
                  })}
                </div>
              </div>

              <div className="p-1 px-md-3">
                <div className="testimonial-slide">
                  <h3 className="mb-4 text-orange">Successful Project</h3>

                  <p className="text-light">
                    Addon Technology engaged to fully understand our business
                    process, and developed a plan to deliver on our exact
                    requirements. Overall we are impressed with Addon Technology
                    professionalism and their degree of engagement to ensure
                    successful project delivery. Hence, we would recommend Addon
                    Technology
                  </p>

                  <h5>APOLLO TYRES LTD,WAGHODIA, GUJARAT</h5>
                  {[...Array(4)].map((_, i) => {
                    return (
                      <FontAwesomeIcon
                        icon={faStar}
                        className="text-orange star-icon"
                        key={i}
                      ></FontAwesomeIcon>
                    );
                  })}
                </div>
              </div>

              <div className="p-1 px-md-3">
                <div className="testimonial-slide">
                  <h3 className="mb-4 text-orange">Good Experiences</h3>

                  <p className="text-light">
                    Addon Technology have not only worked for us to install
                    security system for M.K.G.M School in Karjan, Gujarat, but
                    they have an ability to take our vision a step further and
                    to anticipate our needs for the future. All of this is done
                    in a professional, efficient and courteous manner I would
                    not hesitate to recommend them.
                  </p>

                  <h5>M.K.G.M SCHOOL – KARJAN, GUJARAT</h5>
                  {[...Array(4)].map((_, i) => {
                    return (
                      <FontAwesomeIcon
                        icon={faStar}
                        className="text-orange star-icon"
                        key={i}
                      ></FontAwesomeIcon>
                    );
                  })}
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>

      {/* contact section */}
      <section className="py-5">
        <div className="container-box py-md-5">
          <div className="row">
            <div className="col-md-6">
              <div className="top-heading" data-aos="fade-up" data-aos-offset="30">
                <img src={headingImg} alt="title icon" />
                <div className="ms-3">
                  <span>Request</span>
                  <h3>For Quote</h3>
                </div>
              </div>

              <p className="paragraph mt-35">
                <span className="text-orange fw-bold">
                  For any business enquiry:
                </span>{" "}
                you can fill our feedback form or contact us through our contact
                number{" "}
                <a href="tel:+91-98244-18222" className="text-orange fw-bold">
                  +91-98244-18222
                </a>
                , Our team will reach out to you within 24 hours!
              </p>

              <form onSubmit={handleQuoteSubmit}>
                <div className="row mt-2">
                  <div className="col-md-6 mt-4">
                    <input
                      type="text"
                      placeholder="Your Name"
                      className="contact-field"
                      name="name"
                      value={quoteData.name}
                      onChange={handleChange}
                    />

                    {errors.name && <span className='text-danger'>{errors.name}</span>}
                  </div>
                  <div className="col-md-6 mt-4">
                    <input
                      type="text"
                      placeholder="Email Address"
                      className="contact-field"
                      name="email"
                      value={quoteData.email}
                      onChange={handleChange}
                    />

                    {errors.email && <span className='text-danger'>{errors.email}</span>}
                  </div>

                  <div className="col-12 mt-4">
                    <select value={quoteData.system} aria-label="Systems" name="system"
                      className="contact-field bg-white" onChange={handleChange}>
                      <option value="Vesda system">Vesda system</option>
                      <option value="Fire Alarm System">Fire Alarm System</option>
                      <option value="CCTV System">CCTV System</option>
                      <option value="Other Products">Other Products</option>
                    </select>
                  </div>

                  <div className="col-12 mt-4">
                    <textarea
                      className="contact-field"
                      placeholder="Other Requirement"
                      rows="5"
                      name="message"
                      value={quoteData.message}
                      onChange={handleChange}
                    ></textarea>
                    {errors.message && <span className='text-danger'>{errors.message}</span>}
                  </div>

                  <div className="col-12 mt-4">
                    <button type='submit' className="orange-btn px-4">
                      get a quote{" "}
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-md-6 ps-md-4">
              <div className="top-heading mt-md-0 mt-5" data-aos="fade-up" data-aos-offset="20">
                <img src={headingImg} alt="title icon" />
                <div className="ms-3">
                  <span>General</span>
                  <h3>Questions</h3>
                </div>
              </div>

              <div className="mt-5">
                <ul className="accordian-element ps-0">
                  <li>
                    <input
                      type="radio"
                      name="accordian-element"
                      id="first"
                      defaultChecked={true}
                    />
                    <label htmlFor="first">
                      How many years of on ground experience does the company
                      have?
                    </label>

                    <div className="content">
                      <p>
                        Addon was established in Vadodara, Gujarat, India. it
                        has more than 15 years of on ground experience with
                        clients from all across the nation as well as pan India.
                        We have been constantly updating our products and
                        services in order to ensure good customer response.
                      </p>
                    </div>
                  </li>

                  <li>
                    <input type="radio" name="accordian-element" id="second" />
                    <label htmlFor="second">
                      Does your company extend to post- installation services?
                    </label>

                    <div className="content">
                      <p>
                        Yes, we believe in taking charge of the solutions we
                        offer and we not only make sure that the systems are
                        rightly installed but also take care of the maintenance
                        and proper functioning of the installed systems.
                      </p>
                    </div>
                  </li>

                  <li>
                    <input type="radio" name="accordian-element" id="third" />
                    <label htmlFor="third">
                      What different kinds of solutions does your company
                      provide?
                    </label>

                    <div className="content">
                      <p>
                        The solutions and products we offer to our customers are
                        wide in range. These include Vesda systems (our area of
                        expertise), CCTV systems, fire alarm systems, access
                        control systems, gate automation, digital signage
                        systems and PA systems. If you have any further queries
                        regarding the solutions we offer, you can contact us on
                        the given details.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* contact us banner */}
      <section className="contact-banner py-5 text-white">
        <div className="py-md-5 py-4">
          <div className="row ps-md-0 ps-4 justify-content-center w-100">
            <div className="col-md-7 text-center ">
              <h2 className="fw-bold display-6" data-aos="fade-up" data-aos-offset="10">Want to contact us?</h2>

              <p className="my-4 paragraph text-center text-white" data-aos="fade-up" data-aos-offset="30">
                For any business enquiry, you can fill our feedback form or
                contact us through our contact number +91-98244-18222. Our team
                will reach out to you within 24 hours!
              </p>

              <div className="pt-3" data-aos="fade-up" data-aos-offset="20">
                <Link to={`${Config.appUrl}/contact-us`} className="orange-btn banner-btn px-4">
                  contact us today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* blogs section */}
      <section className="py-5">
        <div className="container-box py-md-5 position-relative">
          <div className="top-heading" data-aos="fade-up" data-aos-offset="40">
            <img src={headingImg} alt="title icon" />
            <div className="ms-3">
              <span>Blogs</span>
              <h3>Check our blog!</h3>
            </div>
          </div>

          <div className="blogs mt-5">
            <Slider {...blogsSettings}>
              {
                blogs.map((blog) => {
                  return (
                    <div key={blog?.id} className="px-md-3 px-2" aria-hidden={true}> 
                      <Link to={`${Config.appUrl}/${blog?.slug.toLowerCase()}`} tabIndex="-1" className="text-dark">
                        <div className="blog-card">
                          <div className="top-img bg-white border border-bottom-0">
                            <img src={`https://www.addontechno.com/admin/assets/front/img/blogs/${blog?.main_image}`} alt="blogImg" />
                            <div>
                              <FontAwesomeIcon
                                icon={faLink}
                                className="text-orange link-icon"
                              ></FontAwesomeIcon>
                            </div>
                          </div>
                          <div className="border">
                            <div className="row px-3 pt-3">
                              <p className="col-6 pe-0 border-end border-secondary blog-date">
                                By <Link to={`${Config.appUrl}/author/mehul-goswami`} tabIndex="-1" className="text-dark hover-red">Mehul Goswami</Link>
                              </p>
                              <p className="col-6 ps-md-4 ps-0 text-end blog-date">
                                {new Date(blog?.created_at)
                                  .toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                              </p>
                            </div>

                            <div className="px-3 blog-card-content">
                              <h5 className="fw-bold text-dark-blue">
                                {blog?.title}
                              </h5>
                              <p className="blog-desc">
                                {blog.content.replace(/<[^>]+>/g, '').slice(0, 140)}
                                {blog.content.replace(/<[^>]+>/g, '').length > 140 && '...'}
                              </p>
                            </div>

                            <div className="d-flex border-top fw-500">
                              <div className="col-6 border-end ps-3 py-2">
                                  Read More
                              </div>
                              <div className="col-6 pe-3 py-2 text-end">
                                <FontAwesomeIcon
                                  icon={faComments}
                                  className="text-secondary star-icon"
                                ></FontAwesomeIcon>{" "}
                                {blog.totalComments} Comments
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </Slider>
          </div>
        </div>
      </section>

      {/* Clients section */}
      <section className="py-5 bg-light-gray">
        <div className="container-box">
          <div className="top-heading mb-5" data-aos="fade-up" data-aos-offset="40">
            <img src={headingImg} alt="title icon" />
            <div className="ms-3">
              <span>Our Clients</span>
              <h3>Meet with our clients</h3>
            </div>
          </div>
          <Slider {...clientSettings}>
            {clients.map((client, index) => {
              return (
                <div key={index} className="px-md-3 px-1  ">
                  <div className="d-flex align-items-center" style={{ minHeight: '150px' }}>
                    <img src={client} alt="client-Logo" className="w-100 h-100 client-logo" />
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Home;
