import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import { faCheckToSlot, faClock, faGear, faHandsClapping } from "@fortawesome/free-solid-svg-icons";

// component
import ProductDetail from '../ProductDetail';

// images 
import vesdaSystemImg from "../../assets/images/products/vesda-system-detail.jpg"
import { Helmet } from 'react-helmet';
import { Config } from '../../Config';

const VesdaSystem = () => {

    useEffect(() => window.scrollTo(0, 0), []);

    const sideTabLinks = [
        {
            title: 'Fire Alarm Systems',
            link: `${Config.appUrl}/service/fire-alarm-systems`
        },
        {
            title: 'Vesda Systems',
            link: `${Config.appUrl}/service/vesda-system`
        },
        {
            title: 'CCTV Systems',
            link: `${Config.appUrl}/service/cctv-systems`
        }
    ]

    const productDetails = {
        name: 'Vesda Systems',
        image: vesdaSystemImg,
        meta_description: "Upgrade to our advanced smoke detection systems. Our VESDA smoke detectors provide early warning and reliable protection. We are a trusted dealer in Gujarat",
        paragraphs: [
            "VESDA stands for Very Early Smoke Detection Apparatus. As the name suggests, Vesda systems are specially made systems that can detect smoke immediately, at the earliest stage of fire or any other smoke related issue. It is the most advanced system that ensures security from any kind of fire emergency.",
            "Our Vesda system serves to be a strong fire defense system that can protect places which have high air flow where better smoke sensitivity is required. We offer you systems that not only have multiple alarm/warning features but also highly sensitive smoke detectors that can notify any possible chances of fire before it spreads."
        ],
        features: [
            {
                title: 'Networking',
                desc: 'We construct a proper system of network that connects all the types of devices to a central control unit that manages the devices and ensure that they operate efficiently together as well as individually.',
                icon: faHandsClapping
            },
            {
                title: 'Central control',
                desc: 'We install special control panels that connect the networks and devices together and make sure that any chances of errors are avoided. Our control panels are flexible in nature that can be adjusted if the area of security or number of devices increase/decrease.',
                icon: faCheckToSlot
            },
            {
                title: 'Smoke sensitivity',
                desc: 'We provide solutions that have the option of adjusting smoke sensitivity according to your needs. We also have auto mode that adjusts the systems settings automatically, according to the type and size of space.',
                icon: faGear
            },
            {
                title: 'Multiple alarms',
                desc: 'Our Vesda systems are specially made with multiple alarms which make sure that the warning is reached to all. Our warning alarms use both sound as well as light alarms.',
                icon: faClock
            }
        ],
        lastParagraph: <p className='paragraph'><Link to={`${Config.appUrl}/how-is-a-vesda-system-going-to-help-your-business`} className='text-orange'>Vesda Systems</Link> being our area of speciality, we can assure you that we will offer you the best range of smoke detection systems from our end that will properly do what it is meant to do. We understand how important security is in businesses and factories and thus come up with the safest systems.</p>,
    }

    return (
        <>
            {/* adding dynamic description */}
            <Helmet>
                <title>VESDA Fire Suppression System Services Supplier Gujarat | Addon Techno</title>
                <meta name="description" content="Upgrade to our advanced smoke detection systems. Our VESDA smoke detectors provide early warning and reliable protection. We are a trusted dealer in Gujarat" />
                <meta name='keywords' content={`smoke detector alarm, vesda systems, early smoke detection system, vesda smoke detection system, very early smoke detection system, smoke detection, smoke detection systems, smoke detectors, smoke detector fire alarm system, fire and smoke alarm system, VESDA Supplier Gujarat, smoke alarm installer, vesda smoke detector, vesda fire suppression system, vesda fire detection system`} />
            </Helmet>

            <ProductDetail sideTabLinks={sideTabLinks} productDetails={productDetails} />
        </>
    )
}

export default VesdaSystem