import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faLink } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import swal from "sweetalert";

// images
import headingImg from "../../assets/images/heading-img.png";

// products
import service1 from "../../assets/images/services/access-control-system.jpg";
import service2 from "../../assets/images/services/eye-in-every-corner.jpg";
import service3 from "../../assets/images/services/fire-alarm-system.jpg";
import service4 from "../../assets/images/services/gate-automatiation.jpg";
import loaderImg from '../../assets/images/loader.gif'
import { Helmet } from "react-helmet";
import { Config } from "../../Config";

const Services = () => {


  const [loading, setLoading] = useState(false);

  // state for form data
  const [contactData, setContactData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });
  // state for errors messages
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });

  useEffect(() => window.scrollTo(0, 0), []);

  // form validations
  const validateForm = () => {
    const newErrors = {};

    if (!contactData.firstName) {
      newErrors.firstName = 'First name is required';
    }

    if (!contactData.lastName) {
      newErrors.lastName = 'Last name is required';
    }

    if (!contactData.email) {
      newErrors.email = 'Email is required';
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(contactData.email)) {
      newErrors.email = 'Invalid email format';
    }

    if (!contactData.phone) {
      newErrors.phone = 'Phone number is required';
    } else if (contactData.phone.length > 10 || contactData.phone.length < 10) {
      newErrors.phone = 'Phone number must be of 10 digits';
    }

    if (!contactData.message) {
      newErrors.message = 'Your Query is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // sending clients query
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (validateForm()) {
      setLoading(true)

      try {
        const formData = new FormData();

        formData.append('fname', contactData.firstName);
        formData.append('lname', contactData.lastName);
        formData.append('email', contactData.email);
        formData.append('phone', contactData.phone);
        formData.append('help', contactData.message);

        await axios.post(`${Config.apiUrl}/contact`, formData, {
          headers: {
            'Content-Type': 'application/json',
          }
        })

        swal("Message Sent", "Your Query has been sent successfully", "success");
        setLoading(false)
        setContactData(
          {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            message: '',
          }
        )
      } catch (error) {
        console.log(error)
        setLoading(false)
        swal("Something Went Wrong!", "", "error");
      }

    }
  }

  // set Form Data
  const handleChange = (e) => {
    setContactData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const services = [
    {
      name: "Installation",
      desc: "We provide appropriate installation services in order to set up and put in place the products and systems in a way that function and work well.",
      image: service1,
    },
    {
      name: "Service & Maintainance",
      desc: "From our end, we offer you maintenance services that ensure longevity and effective working of the systems we have installed. We hold responsibility of our systems and our services don’t end when the installation is done.",
      image: service2,
    },
    {
      name: "System Design",
      desc: "We design systems that serve the purpose of the product rightly and function the way they should to ensure that the expected results are achieved.",
      image: service3,
    },
    {
      name: "Tested Products",
      desc: "Our products are properly tested by a team of professionals before installation to make sure that the products serve the intended purpose and work efficiently.",
      image: service4,
    },
  ];

  return (
    <>

      <Helmet>
        <title>Trusted CCTV Camera Installation and Maintenance in Gujarat</title>

        <meta name="description" content="Ensure robust security with our professional CCTV camera installation services in Gujarat. We also provide CCTV design, service and maintenance in Gujarat." />
        <meta name="keywords" content="cctv installation" />
      </Helmet>

      {/* loading */}
      <div className={`loader ${loading ? 'd-flex' : 'd-none'}`}>
        <img src={loaderImg} alt="loaderImage" />
      </div>

      <section className="page-header py-5">
        <div className="container-box py-4">
          <div className="text-white">
            <h1 className="fw-bold display-5">Services</h1>
            <p className="mt-4 header-path-link">
              <Link to={`${Config.appUrl}/`} className="text-orange">
                {" "}
                Home
              </Link>
              <FontAwesomeIcon
                icon={faArrowRight}
                className="social-icon me-3"
              ></FontAwesomeIcon>
              Services
            </p>
          </div>
        </div>
      </section>

      {/* Other services */}

      <section className="py-md-5 pb-5">
        <div className="container-box">
          <div className="row justify-content-center ">
            {services.map((service, index) => {
              return (
                <div key={index} className="col-sm-6 px-3 mt-5">
                  <div className="product-card">
                    <div className="product-img-box service-img-box border">
                      <img src={service.image} alt="ServiceImage" />
                      <div className="hover-effect-box">
                        <div>
                          <FontAwesomeIcon
                            icon={faLink}
                            className="text-orange link-icon"
                          ></FontAwesomeIcon>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 product-content">
                      <h4 className="text-dark-blue"> {service.name} </h4>

                      <p className="paragraph mt-3">{service.desc}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* Enquiry form */}
      <section className='py-5 enquiry-form'>
        <div className="container-box py-md-5" >
          <div className="top-heading mb-4">
            <img src={headingImg} alt="title icon" />
            <div className='ms-3'>
              <span>QUICK</span>
              <h3 className='text-white'>ENQUIRY FORM</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-7 col-lg-6 col-xxl-5">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-6 mb-4">
                    <input type="text" name='firstName'
                      value={contactData.firstName}
                      onChange={handleChange}
                      placeholder='First Name*'
                      className='contact-field' />

                    {errors.firstName && <span className='text-danger'>{errors.firstName}</span>}
                  </div>
                  <div className="col-sm-6 mb-4">
                    <input type="text" name='lastName'
                      value={contactData.lastName}
                      onChange={handleChange}
                      placeholder='Last Name'
                      className='contact-field' />

                    {errors.lastName && <span className='text-danger'>{errors.lastName}</span>}
                  </div>

                  <div className="col-sm-6 mb-4">
                    <input type="text" name='email'
                      value={contactData.email}
                      onChange={handleChange}
                      placeholder='Email Address'
                      className='contact-field' />

                    {errors.email && <span className='text-danger'>{errors.email}</span>}
                  </div>
                  <div className="col-sm-6 mb-4">
                    <input type="number" name='phone'
                      value={contactData.phone}
                      onChange={handleChange}
                      placeholder='Phone'
                      className='contact-field' />

                    {errors.phone && <span className='text-danger'>{errors.phone}</span>}
                  </div>

                  <div className="col-12 mb-4">
                    <textarea className='contact-field'
                      name='message'
                      value={contactData.message}
                      onChange={handleChange}
                      placeholder='How can we help?'
                      rows="5"></textarea>

                    {errors.message && <span className='text-danger'>{errors.message}</span>}
                  </div>

                  <div className="col-12">
                    <button type='submit' className='orange-btn px-4'> send message </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
