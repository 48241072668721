import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faLink } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import swal from "sweetalert";
// images
import headingImg from "../../assets/images/heading-img.png";

// products
import product1 from "../../assets/images/products/Access-Control-3.jpg";
import product2 from "../../assets/images/products/Digital-Signage.jpg";
import product3 from "../../assets/images/products/PA-System.jpg";
import loaderImg from "../../assets/images/loader.gif";
import { Helmet } from "react-helmet";
import { Config } from "../../Config";

const OtherProducts = () => {
  const [loading, setLoading] = useState(false);

  // state for form data
  const [contactData, setContactData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  // state for errors messages
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  useEffect(() => window.scrollTo(0, 0), []);

  const products = [
    {
      name: "Access Control",
      desc: "The access control systems we bring to you are reliable and trustworthy that will functionally help in managing access to your spaces of any size and lessen or remove the chances of any kind of security and confidentiality risks.",
      image: product1,
      link: `${Config.appUrl}/other-products/access-control`,
    },
    {
      name: "Digital Signage Solution",
      desc: "We offer you Digital signage solutions that enable you to broadcast your A/V to a large crowd through digital screens that can be connected and broadcasted at any desired place. Our systems are flexible in nature enabling digital display on the size and type of screen you want.",
      image: product2,
      link: `${Config.appUrl}/other-products/digital-signage-solution`,
    },
    {
      name: "PA System",
      desc: "Our range of Public address systems are well tested, offering the customers the exact kind of audio broadcast medium that can effectively reach the crowd. Our systems are known for the quality of audio and the broad span of features it offers.",
      image: product3,
      link: `${Config.appUrl}/other-products/pa-system`,
    },
  ];

  // form validations
  const validateForm = () => {
    const newErrors = {};

    if (!contactData.firstName) {
      newErrors.firstName = "First name is required";
    }

    if (!contactData.lastName) {
      newErrors.lastName = "Last name is required";
    }

    if (!contactData.email) {
      newErrors.email = "Email is required";
    } else if (
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(contactData.email)
    ) {
      newErrors.email = "Invalid email format";
    }

    if (!contactData.phone) {
      newErrors.phone = "Phone number is required";
    } else if (contactData.phone.length > 10 || contactData.phone.length < 10) {
      newErrors.phone = "Phone number must be of 10 digits";
    }

    if (!contactData.message) {
      newErrors.message = "Your Query is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // sending clients query
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);

      try {
        const formData = new FormData();

        formData.append("fname", contactData.firstName);
        formData.append("lname", contactData.lastName);
        formData.append("email", contactData.email);
        formData.append("phone", contactData.phone);
        formData.append("help", contactData.message);

        await axios.post(
          `${Config.apiUrl}/contact`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        swal(
          "Message Sent",
          "Your Query has been sent successfully",
          "success"
        );
        setLoading(false);
        setContactData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          message: "",
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
        swal("Something Went Wrong!", "", "error");
      }
    }
  };

  // set Form Data
  const handleChange = (e) => {
    setContactData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <Helmet>
        <title>Advanced CCTV Camera Systems Gujarat | Addon Techno</title>
        <meta
          name="description"
          content="Experience robust security with our advanced CCTV systems in Gujarat. Our comprehensive solutions include access control, digital signage, and PA systems."
        />
        <meta name="keywords" content={`cctv systems`} />
      </Helmet>

      {/* loading */}
      <div className={`loader ${loading ? "d-flex" : "d-none"}`}>
        <img src={loaderImg} alt="loaderImage" />
      </div>

      <section className="page-header py-5">
        <div className="container-box py-4">
          <div className="text-white">
            <h1 className="fw-bold display-5">Other Products</h1>
            <p className="mt-4 header-path-link">
              <Link to={`${Config.appUrl}/`} className="text-orange">
                {" "}
                Home
              </Link>
              <FontAwesomeIcon
                icon={faArrowRight}
                className="social-icon me-3"
              ></FontAwesomeIcon>
              Other Products
            </p>
          </div>
        </div>
      </section>

      {/* Other Products */}
      <section className="py-md-5 ">
        <div className="container-box pb-5">
          <div className="row">
            {products.map((product, index) => {
              return (
                <div key={index} className="col-lg-4 col-sm-6 px-3 mt-5">
                  <Link to={product.link}>
                    <div className="product-card">
                      <div className="product-img-box border">
                        <img src={product.image} alt="productImage" />

                        {/* hover box */}
                        <div className="hover-effect-box">
                          <div>
                            <FontAwesomeIcon
                              icon={faLink}
                              className="text-orange link-icon"
                            ></FontAwesomeIcon>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 product-content">
                        <h4 className="text-dark-blue"> {product.name} </h4>

                        <p className="paragraph mt-3">{product.desc}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* Enquiry form */}
      <section className="py-5 enquiry-form">
        <div className="container-box py-md-5">
          <div className="top-heading mb-4">
            <img src={headingImg} alt="title icon" />
            <div className="ms-3">
              <span>QUICK</span>
              <h3 className="text-white">ENQUIRY FORM</h3>
            </div>
          </div>

          <div className="row">
            <div className="col-md-7 col-lg-6 col-xxl-5">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-6 mb-4">
                    <input
                      type="text"
                      name="firstName"
                      value={contactData.firstName}
                      onChange={handleChange}
                      placeholder="First Name*"
                      className="contact-field"
                    />

                    {errors.firstName && (
                      <span className="text-danger">{errors.firstName}</span>
                    )}
                  </div>
                  <div className="col-sm-6 mb-4">
                    <input
                      type="text"
                      name="lastName"
                      value={contactData.lastName}
                      onChange={handleChange}
                      placeholder="Last Name"
                      className="contact-field"
                    />

                    {errors.lastName && (
                      <span className="text-danger">{errors.lastName}</span>
                    )}
                  </div>

                  <div className="col-sm-6 mb-4">
                    <input
                      type="text"
                      name="email"
                      value={contactData.email}
                      onChange={handleChange}
                      placeholder="Email Address"
                      className="contact-field"
                    />

                    {errors.email && (
                      <span className="text-danger">{errors.email}</span>
                    )}
                  </div>
                  <div className="col-sm-6 mb-4">
                    <input
                      type="number"
                      name="phone"
                      value={contactData.phone}
                      onChange={handleChange}
                      placeholder="Phone"
                      className="contact-field"
                    />

                    {errors.phone && (
                      <span className="text-danger">{errors.phone}</span>
                    )}
                  </div>

                  <div className="col-12 mb-4">
                    <textarea
                      className="contact-field"
                      name="message"
                      value={contactData.message}
                      onChange={handleChange}
                      placeholder="How can we help?"
                      rows="5"
                    ></textarea>

                    {errors.message && (
                      <span className="text-danger">{errors.message}</span>
                    )}
                  </div>

                  <div className="col-12">
                    <button type="submit" className="orange-btn px-4">
                      {" "}
                      send message{" "}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OtherProducts;
