import React, { useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faEnvelope, faPhone, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';

import { Link, NavLink } from 'react-router-dom'

// images
import logo from '../../assets/images/logo.png'
import { Config } from '../../Config';

const Navbar = () => {

    const [stickyNavbar, setStickyNavbar] = useState(false)
    const [showNavbar, setShowNavbar] = useState(false)

    window.addEventListener('scroll', () => {
        if (window.scrollY > 125) {
            setStickyNavbar(true);
        } else {
            setStickyNavbar(false);
        }
    })

    return (
        <>
            <div>
                <header>
                    <div className='container-box bg-dark-blue py-3 d-flex justify-content-md-between justify-content-end align-items-center'>
                        <div className='contact-details d-md-flex align-items-center d-none'>
                            <div>
                                <FontAwesomeIcon
                                    icon={faPhone}
                                    className='contact-icon'
                                ></FontAwesomeIcon>
                                <Link to="tel:+91-98244-18222" className='ps-3 hover-red'>+91-98244-18222</Link>
                            </div>

                            <div className='ms-4'>
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    className='contact-icon'
                                ></FontAwesomeIcon>
                                <Link to="mailto:sales@addontechno.com" className='ps-3 hover-red'>sales@addontechno.com</Link>
                            </div>
                        </div>

                        <div className='social-icons'>
                            <a href="https://www.facebook.com/addontechnology" aria-label="facebook link" target='_blank' rel="noreferrer">
                                <FontAwesomeIcon
                                    icon={faFacebookF}
                                    className='social-icon'
                                ></FontAwesomeIcon>
                            </a>

                            <a href="https://www.twitter.com/addontechno" aria-label="twitter link" target='_blank' rel="noreferrer">
                                <FontAwesomeIcon
                                    icon={faTwitter}
                                    className='social-icon'
                                ></FontAwesomeIcon>
                            </a>

                            <a href="https://www.linkedin.com/company/addontechnology" aria-label='linkedIn link' target='_blank' rel="noreferrer">
                                <FontAwesomeIcon
                                    icon={faLinkedinIn}
                                    className='social-icon'
                                ></FontAwesomeIcon>
                            </a>
                        </div>
                    </div>
                </header>

                <nav className={`container-box navibar ${stickyNavbar && 'stickyNav'}`}>
                    <div>
                        <NavLink to={`${Config.appUrl}/`}>
                            <img src={logo} alt="Addon logo" className='logo' />
                        </NavLink>
                    </div>
                    <ul className={`navlinks ps-0 ${showNavbar ? 'showNavbar' : ''}`}>
                        <li><NavLink to={`${Config.appUrl}/`} onClick={() => setShowNavbar(false)}> Home </NavLink></li>
                        <li><NavLink to={`${Config.appUrl}/service/vesda-system`} onClick={() => setShowNavbar(false)}> Vesda Systems </NavLink></li>
                        <li><NavLink to={`${Config.appUrl}/service/fire-alarm-systems`} onClick={() => setShowNavbar(false)}> Fire Alarm Systems </NavLink></li>
                        <li><NavLink to={`${Config.appUrl}/service/cctv-systems`} onClick={() => setShowNavbar(false)}> CCTV Systems </NavLink></li>
                        <li><NavLink to={`${Config.appUrl}/other-products`} onClick={() => setShowNavbar(false)}> Other Products </NavLink></li>
                        <li><NavLink to={`${Config.appUrl}/blog`}onClick={() => setShowNavbar(false)}> Blog </NavLink></li>
                        <li><NavLink to={`${Config.appUrl}/contact-us`} onClick={() => setShowNavbar(false)}> Contact us </NavLink></li>
                    </ul>
                    <div className='menu-btn'>
                        <FontAwesomeIcon
                            icon={showNavbar ? faTimes : faBars}
                            className='fs-2'
                            onClick={() => setShowNavbar(!showNavbar)}
                        ></FontAwesomeIcon>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Navbar