import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCheckToSlot,
  faGear,
  faHandsClapping,
} from "@fortawesome/free-solid-svg-icons";

// animation package
import AOS from 'aos';
import 'aos/dist/aos.css';

// images
import mehulImg from "../../assets/images/mehul_goswami_img.webp";
import signature from "../../assets/images/mehul_signature.png";
import headingImg from "../../assets/images/heading-img.png";
import AboutImg1 from "../../assets/images/about-left-thumb.png";
import AboutImg2 from "../../assets/images/about-right-thumb-2.png";
import { Helmet } from "react-helmet";
import { Config } from "../../Config";

const AboutUs = () => {

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    AOS.init({
      offset: 100, // values from 0 to 3000, with step 50ms
      duration: 800, // values from 0 to 3000, with step 50ms
      once: true,
    });
  }, [])

  const progressData = [
    {
      title: "EPC Works",
      percentage: 95,
    },
    {
      title: "Customer Satisfaction",
      percentage: 85,
    },
    {
      title: "Utilization Rate",
      percentage: 97,
    },
    {
      title: "Project Done",
      percentage: 97,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Leading Supplier and Dealer for CCTV Camera and VESDA Fire System Gujarat</title>

        <meta name="description" content="Discover our expertise in CCTV camera installation and VESDA fire system solutions in Vadodara, Gujarat. Trust us for cutting-edge security solutions." />
        <meta name="keywords" content="cctv camera installation, vesda fire system" />  
      </Helmet>

      <section className="page-header py-5">
        <div className="container-box py-4">
          <div className="text-white">
            <h1 className="fw-bold display-5">About Us</h1>
            <p className="mt-4 header-path-link">
              <Link to={`${Config.appUrl}/`} className="text-orange">
                {" "}
                Home
              </Link>
              <FontAwesomeIcon
                icon={faArrowRight}
                className="social-icon me-3"
              ></FontAwesomeIcon>
              About Us
            </p>
          </div>
        </div>
      </section>

      {/* about us section */}
      <section className="py-md-5">
        <div className="container-box pb-5">
          <div className="row">
            <div className="col-md-6 mt-5">
              <div>
                <ul className="accordian-element ps-0">
                  <li>
                    <input
                      type="radio"
                      name="accordian-element"
                      id="first"
                      defaultChecked={true}
                    />
                    <label htmlFor="first">Company strategies</label>

                    <div className="content">
                      <p>
                        We direct towards serving our clients what they actually
                        need. We adopt need oriented strategies for our clients
                        in order to hand over the best to them. We outdo their
                        needs by delivering them the highest quality systems and
                        solutions along with assured service and support from
                        our end.
                      </p>
                    </div>
                  </li>

                  <li>
                    <input type="radio" name="accordian-element" id="second" />
                    <label htmlFor="second">Our Vision</label>

                    <div className="content">
                      <p>
                        Our vision lies in becoming the best service providing
                        company in the field of security and solutions. We wish
                        to provide our clients with the best quality service and
                        list among the most known and reputed companies across
                        the world.
                      </p>
                    </div>
                  </li>

                  <li>
                    <input type="radio" name="accordian-element" id="third" />
                    <label htmlFor="third">Our Mission</label>

                    <div className="content">
                      <p>
                        Currently extending our services till Pan India, we now
                        are working on expanding our services to international
                        clients as well and giving them the best solutions and
                        systems from our ends. Our aim behind extending our
                        services is to get international recognition and have
                        the opportunity to be known by a wider segment of
                        businesses.
                      </p>
                    </div>
                  </li>

                  <li>
                    <input type="radio" name="accordian-element" id="fourth" />
                    <label htmlFor="fourth">Future Plans</label>

                    <div className="content">
                      <p>
                        In the near future, we have both short-term as well as
                        long term goals lined up wherein we not only aim at
                        expanding our services but also implementing and
                        planning strategies to gain customer satisfaction as
                        well as updating our services and solutions.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 mt-5 ps-md-4">
              <div className="row">
                <div className="col-6 pe-md-3">
                  <img src={AboutImg1} alt="firex smoke alarm" className="img-fluid" />
                </div>
                <div className="col-6 ps-md-3">
                  <img src={AboutImg2} alt="complete cctv security system" className="img-fluid" />
                </div>
              </div>

              <h4 className="fw-bold text-dark-blue my-4">
                We are a leading company, holding professional expertise in this
                field and specialising in Vesda systems.
              </h4>

              <p className="paragraph">
                We have been in this field for more than 15 years and Addon is a
                leading company in Gujarat along with being the most preferable
                supplier of Vesda systems across the state.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* inspiring section */}
      <section className="inspiring-section owner-bg py-5 position-relative">
        <div className="container-box">
          <div className="row py-md-5 justify-content-between">
            <div className="col-lg-5 ">
              <div className="top-heading">
                <img src={headingImg} alt="title icon" />
                <div className="ms-3">
                  <span>Dream Big</span>
                  <h3>Inspiring Solutions</h3>
                </div>
              </div>

              <div>
                <p className="paragraph mt-35">
                  “Being the initiator of the company, the purpose upon which I
                  run my company is not profit making, rather “customer welfare”
                  in order to keep them happy and provide them with the best
                  solutions they are looking for. Our clients are our
                  responsibility, and we from our end ensure quality service and
                  high end products that can make your business safer and better.
                  “
                </p>
              </div>

              <div className="mt-5 pt-4">
                <img src={signature} alt="signature" className="img-fluid" />
                <h5 className="inspiring-author mt-4">Mehul Goswami</h5>
                <h6 className="inspiring-author-role">CEO &amp; Founder</h6>
              </div>
            </div>
            <div className="col-lg-6 mt-lg-0 mt-5">
              <img src={mehulImg} alt="ownerImage" className="w-100 rounded ownerImage" />
            </div>
          </div>

        </div>
      </section>

      {/* why choose us */}
      <section className="pt-md-5 pb-5">
        <div className="container-box pt-5">
          <div className="row">
            <div className="col-md-6 pe-md-5">
              <div className="top-heading mb-5">
                <img src={headingImg} alt="title icon" />
                <div className="ms-3">
                  <span>Why</span>
                  <h3>Choose Us?</h3>
                </div>
              </div>
              {progressData.map((progress, index) => {
                return (
                  <div key={index} className="mb-4">
                    <div className="progress-bar p-1">
                      <div
                        className="progress-fill"
                        style={{ width: `${progress.percentage}%` }}
                      ></div>
                    </div>
                    <div className="d-flex justify-content-between px-2 mt-2">
                      <h6 className=" fw-bold text-dark-blue">
                        {progress.title}
                      </h6>
                      <h6 className=" fw-bold text-dark-blue">
                        {progress.percentage}%
                      </h6>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="col-md-6 ps-md-0 mt-md-0 mt-5">
              <div className="pb-3 d-flex align-items-start border-dashed-bottom">
                <div className="company-icon p-3 border rounded">
                  <FontAwesomeIcon
                    icon={faHandsClapping}
                    className="fs-2 text-orange"
                  ></FontAwesomeIcon>
                </div>
                <div className="ps-4">
                  <h5 className="text-dark-blue fw-bold">
                    Installation and service
                  </h5>
                  <p className="paragraph mb-0">
                    Our solutions are not just limited to providing you the
                    systems. We also take care of the installation process as
                    well as maintenance and service of the same in order to
                    assure you that we take charge of anything that we serve.
                  </p>
                </div>
              </div>

              <div className="py-3 d-flex align-items-start border-dashed-bottom">
                <div className="company-icon p-3 border rounded">
                  <FontAwesomeIcon
                    icon={faCheckToSlot}
                    className="fs-2 text-orange"
                  ></FontAwesomeIcon>
                </div>
                <div className="ps-4">
                  <h5 className="text-dark-blue fw-bold">Tested products</h5>
                  <p className="paragraph mb-0">
                    Our systems are well developed and appropriately tested
                    before installation in order to get the best results. We
                    have a special team allotted to work on the pilot tests and
                    trials of the systems in order to ensure their efficient
                    functioning and durability.
                  </p>
                </div>
              </div>

              <div className="py-3 d-flex align-items-start">
                <div className="company-icon p-3 border rounded">
                  <FontAwesomeIcon
                    icon={faGear}
                    className="fs-2 text-orange"
                  ></FontAwesomeIcon>
                </div>
                <div className="ps-4">
                  <h5 className="text-dark-blue fw-bold">System Design</h5>
                  <p className="paragraph mb-0">
                    Designing a system properly is very important in order to
                    make sure that it works the way it should. From designing
                    the products to laying out the drafts of the networks, our
                    designing team maps the systems in a way that will assure
                    that the systems function well and in coordination with the
                    networks and spaces.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* contact us banner */}
      <section className="contact-banner py-5 text-white">
        <div className="py-md-5 py-4">
          <div className="row ps-md-0 ps-4 justify-content-center w-100">
            <div className="col-md-7 text-center ">
              <h2 className="fw-bold display-6" data-aos="fade-up" data-aos-offset="10">Want to contact us?</h2>

              <p className="my-4 paragraph text-center text-white" data-aos="fade-up" data-aos-offset="30">
                For any business enquiry, you can fill our feedback form or
                contact us through our contact number +91-98244-18222. Our team
                will reach out to you within 24 hours!
              </p>

              <div className="pt-3" data-aos="fade-up" data-aos-offset="20">
                <Link to={`${Config.appUrl}/contact-us`} className="orange-btn banner-btn px-4">
                  contact us today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
