import React, { useEffect, useState } from "react";
import footerLogo from "../../assets/images/footer-logo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { Config } from "../../Config";

const Footer = () => {
  const [recentBlogs, setRecentBlogs] = useState([]);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");

  const getRecentBlogs = async () => {
    try {
      const response = await axios.get(
        `${Config.apiUrl}/mainpage`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setRecentBlogs(response.data.blog);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRecentBlogs();
  }, []);

  // integrating api for sign up form
  const handleSignUpSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("name", fullName);
      formData.append("email", email);

      await axios.post(
        `${Config.apiUrl}/signup`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      swal("", "You Signed Up Successfully!", "success");

      setFullName("");
      setEmail("");
    } catch (error) {
      console.log(error);
      swal("Something went wrong", "", "error");
    }
  };

  return (
    <>
      <footer>
        <div className="container-box top-footer">
          <div className="row align-items-center">
            <div className="col-md-3 mb-md-0 mb-2">
              <Link to={`${Config.appUrl}/`}>
                <img
                  src={footerLogo}
                  className="footer-logo"
                  alt="Newsletter Logo"
                />
              </Link>
            </div>

            <div className="col-md-9 ">
              <form
                action=""
                onSubmit={handleSignUpSubmit}
                className="subscription-form d-flex align-items-md-center align-items-start justify-content-end flex-md-row flex-column"
              >
                <input
                  type="text"
                  placeholder="Name"
                  required
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />

                <input
                  type="email"
                  placeholder="Email Address"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button type="submit" className="orange-btn">
                  Sign Up
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="container-box bottom-footer">
          <div className="row">
            <div className="col-xxl-2  col-md-3 mb-md-0 mb-4">
              <h5 className="fw-bold text-white mb-4">Our Company</h5>
              <ul className="footer-links">
                <li>
                  <NavLink to={`${Config.appUrl}/about-us`}>About Company</NavLink>
                </li>
                <li>
                  <NavLink to={`${Config.appUrl}/services`}>Our Services</NavLink>
                </li>
                <li>
                  <NavLink to={`${Config.appUrl}/contact-us`}>Contact Us</NavLink>
                </li>
                <li>
                  <NavLink to={`${Config.appUrl}/terms`}>Terms & Conditions</NavLink>
                </li>
                <li>
                  <NavLink to={`${Config.appUrl}/privacy-policy`}>Privacy Policy</NavLink>
                </li>
              </ul>
            </div>
            <div className="col-xxl-2 col-md-3 mb-md-0 mb-4">
              <h5 className="fw-bold text-white mb-4">Our Solutions</h5>
              <ul className="footer-links">
                <li>
                  <NavLink to={`${Config.appUrl}/service/vesda-system`}>Vesda Systems</NavLink>
                </li>
                <li>
                  <NavLink to={`${Config.appUrl}/service/fire-alarm-systems`}>
                    Fire Alarm Systems
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`${Config.appUrl}/service/cctv-systems`}>CCTV Systems</NavLink>
                </li>
                <li>
                  <NavLink to={`${Config.appUrl}/other-products`}>Other Products</NavLink>
                </li>
              </ul>
            </div>

            <div className="col-md-6 col-lg-3 col-xl-3 col-xxl-5 mb-md-0 mb-5">
              <h5 className="fw-bold text-white mb-4">Recent Post</h5>
              <div>
                {recentBlogs?.slice(0, 2).map((blog) => {
                  return (
                    <Link
                      to={`${Config.appUrl}/${blog?.slug.toLowerCase()}`}
                      tabIndex="-1"
                      key={blog?.id}
                    >
                      <div className="d-flex align-items-center recent-post mb-md-2 mb-3">
                        <div>
                          <img
                            src={`https://www.addontechno.com/admin/assets/front/img/blogs/${blog?.main_image}`}
                            alt="blogImage"
                          />
                        </div>
                        <div className="ms-3">
                          <p className="mb-1">
                            {new Date(blog?.updated_at).toLocaleDateString(
                              "en-US",
                              { year: "numeric", month: "long", day: "numeric" }
                            )}
                          </p>
                          <span className="hover-red">
                            {blog?.title.length > 45
                              ? blog?.title.slice(0, 45) + ".."
                              : blog?.title}
                          </span>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>

            <div className="col-md-3">
              <h5 className="fw-bold text-white mb-4">Social Links</h5>

              <div>
                <a
                  href="https://www.facebook.com/addontechnology"
                  aria-label="facebook link"
                  target="_blank"
                  rel="noreferrer"
                  className="footer-social-icon"
                >
                  <FontAwesomeIcon icon={faFacebookF}></FontAwesomeIcon>
                </a>

                <a
                  href="https://www.twitter.com/addontechno"
                  aria-label="twitter link"
                  target="_blank"
                  rel="noreferrer"
                  className="footer-social-icon"
                >
                  <FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon>
                </a>

                <a
                  href="https://www.linkedin.com/company/addontechnology"
                  aria-label="linkedIn link"
                  target="_blank"
                  rel="noreferrer"
                  className="footer-social-icon"
                >
                  <FontAwesomeIcon icon={faLinkedinIn}></FontAwesomeIcon>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container-box copyright">
          <p className="mb-2 text-light">
            Copyrights © 2023 All Rights Reserved by Addon Technology.{" "}
          </p>

          <p className="mb-2 text-light">
            Designed by{" "}
            <a href="https://www.zithas.com/" target="_blank" rel="noreferrer">
              Zithas Technologies Pvt. Ltd.
            </a>{" "}
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
