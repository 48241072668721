import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faSearch,
  faLink,
  faComments,
} from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";

import axios from "axios";
import swal from "sweetalert";

import loaderImg from "../../assets/images/loader.gif";
import { Config } from "../../Config";

const SearchBlog = () => {
  // state for form data
  const [contactData, setContactData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  // state for errors messages
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  // blogs
  const [tags, setTags] = useState([]);
  const [allBlogs, setAllBlogs] = useState([]);
  const [searchBlogs, setSearchBlogs] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const { pathname } = useLocation();
  const { searchStr } = useParams();
  const searchingString = searchStr.replaceAll("+", " ");

  const navigate = useNavigate();

  const getBlogs = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${Config.apiUrl}/mainpage`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const blogCategory = response.data.blog_category.filter((blogCat) =>
        blogCat.name.toLowerCase().includes(searchingString.toLowerCase())
      );

      // main login of searching blog
      const filterBlogs = response.data.blog.filter((blog) => {
        return (
          blog.title.toLowerCase().includes(searchingString.toLowerCase()) ||
          blog.content
            .replace(/<[^>]+>/g, "")
            .toLowerCase()
            .includes(searchingString.toLowerCase()) ||
          (blogCategory.length && blog.bcategory_id === blogCategory[0].id)
        );
      });

      const updatedBlogs = filterBlogs.map((blog) => {
        blog.totalComments = response.data.comment_count.filter(
          (bcomment) => blog.id === bcomment.blog_id
        ).length;
        return blog;
      });

      setSearchBlogs(updatedBlogs);
      setAllBlogs(response.data.blog);
      setTags(response.data.blog_category);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getBlogs();
  }, [pathname]);

  document.title = "Mehul Goswami, Author at Addon Technology";

  // enquiry form api integration -------------------------------
  // form validations

  const validateForm = () => {
    const newErrors = {};

    if (!contactData.firstName) {
      newErrors.firstName = "First name is required";
    }

    if (!contactData.lastName) {
      newErrors.lastName = "Last name is required";
    }

    if (!contactData.email) {
      newErrors.email = "Email is required";
    } else if (
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(contactData.email)
    ) {
      newErrors.email = "Invalid email format";
    }

    if (!contactData.phone) {
      newErrors.phone = "Phone number is required";
    } else if (contactData.phone.length > 10 || contactData.phone.length < 10) {
      newErrors.phone = "Phone number must be of 10 digits";
    }

    if (!contactData.message) {
      newErrors.message = "Your Query is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // sending clients query
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (isVerified) {
        setLoading(true);

        try {
          const formData = new FormData();

          formData.append("fname", contactData.firstName);
          formData.append("lname", contactData.lastName);
          formData.append("email", contactData.email);
          formData.append("phone", contactData.phone);
          formData.append("help", contactData.message);

          await axios.post(
            `${Config.apiUrl}/contact`,
            formData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          swal(
            "Message Sent",
            "Your Query has been sent successfully",
            "success"
          );
          setLoading(false);
          setContactData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            message: "",
          });
          setIsVerified(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
          swal("Something Went Wrong!", "", "error");
        }
      } else {
        swal("Confirm that you are not a robot", "", "warning");
      }
    }
  };

  // set Form Data
  const handleChange = (e) => {
    setContactData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
  // -------------------------------------------------------------

  // Search blog functionality
  const handleSearch = (e) => {
    e.preventDefault();
    const serachSlug = searchValue.replaceAll(" ", "+");
    navigate(`/search/${serachSlug}`);
    setSearchValue("");
  };

  // google recapcha change
  function onCapchaChange(value) {
    setIsVerified(true);
    setTimeout(() => {
      setIsVerified(false);
    }, 120000);
  }

  return (
    <>
      {/* loading */}
      <div className={`loader ${loading ? "d-flex" : "d-none"}`}>
        <img src={loaderImg} alt="loaderImage" />
      </div>

      <section className="page-header py-5">
        <div className="container-box py-4">
          <div className="text-white">
            <h1 className="fw-bold display-5 text-capitalize">
              <span className="text-orange">Search For:</span> {searchingString}
            </h1>
            <p className="mt-4 header-path-link text-capitalize">
              <Link to={`${Config.appUrl}/`} className="text-orange">
                {" "}
                Home
              </Link>
              <FontAwesomeIcon
                icon={faArrowRight}
                className="social-icon me-3"
              ></FontAwesomeIcon>
              Search Results For: {searchingString}
            </p>
          </div>
        </div>
      </section>

      {/* Blogs details */}

      <section className="py-5">
        <div className="container-box py-md-5">
          <div className="row justify-content-center ">
            {/* left section */}
            <div className="col-lg-8 col-md-7">
              {searchBlogs.length > 0 ? (
                searchBlogs?.map((blog) => {
                  return (
                    <div key={blog?.id} aria-hidden="true" className="mb-5">
                      <Link
                        to={`${Config.appUrl}/${blog?.slug.toLowerCase()}`}
                        tabIndex="-1"
                        className="text-dark"
                      >
                        <div className="blog-card">
                          <div className="top-img border border-bottom-0">
                            <img
                              src={`https://www.addontechno.com/admin/assets/front/img/blogs/${blog?.main_image}`}
                              alt="blogImage"
                              className="h-auto"
                            />
                            <div>
                              <FontAwesomeIcon
                                icon={faLink}
                                className="text-orange link-icon"
                              ></FontAwesomeIcon>
                            </div>
                          </div>
                          <div className="border">
                            <div className="d-flex px-3 pt-3">
                              <p className="pe-md-4 pe-2 border-end">
                                By{" "}
                                <Link
                                  to={`${Config.appUrl}/author/mehul-goswami`}
                                  tabIndex="-1"
                                  className="text-dark hover-red"
                                >
                                  Mehul Goswami
                                </Link>
                              </p>
                              <p className="ps-md-4 ps-2">
                                {new Date(blog?.updated_at).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  }
                                )}
                              </p>
                            </div>

                            <div className="px-3 blog-card-content">
                              <h5 className="fw-bold text-dark-blue">
                                {blog?.title}
                              </h5>
                              <p className="blog-desc">
                                {blog.content
                                  .replace(/<[^>]+>/g, "")
                                  .slice(0, 200)}
                                {blog.content.replace(/<[^>]+>/g, "").length >
                                  200 && "..."}
                              </p>
                            </div>

                            <div className="d-flex border-top fw-500">
                              <div className="col-6 border-end ps-3 py-2">
                                Read More
                              </div>
                              <div className="col-6 pe-3 py-2 text-end">
                                <FontAwesomeIcon
                                  icon={faComments}
                                  className="text-secondary star-icon"
                                ></FontAwesomeIcon>{" "}
                                {blog?.totalComments} Comments
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })
              ) : (
                <div>
                  <h1>Nothing Found</h1>
                  <p className="mt-3">
                    Sorry, but nothing matched your search terms. Please try
                    again with some different keywords.
                  </p>

                  <form onSubmit={handleSearch} className="d-md-block d-none">
                    <div className="search-bar d-flex justify-content-between mt-5">
                      <input
                        type="text"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder="Search..."
                        className="px-3 py-2"
                        required
                      />
                      <button>
                        <FontAwesomeIcon
                          icon={faSearch}
                          className=""
                        ></FontAwesomeIcon>
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>

            {/* right section */}
            <div className="col-lg-4 col-md-5 ps-lg-3 mt-md-0 mt-5">
              <form onSubmit={handleSearch}>
                <div className="search-bar d-flex justify-content-between">
                  <input
                    type="text"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Search..."
                    className="px-3 py-2"
                    required
                  />
                  <button>
                    <FontAwesomeIcon
                      icon={faSearch}
                      className=""
                    ></FontAwesomeIcon>
                  </button>
                </div>
              </form>

              {/* enquiry form */}
              <div className="bg-light-gray mt-5 p-4">
                <h4 className="fw-bold mb-4 text-dark-blue">
                  QUICK ENQUIRY FORM
                </h4>
                <form action="" onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <input
                      type="text"
                      name="firstName"
                      value={contactData.firstName}
                      onChange={handleChange}
                      placeholder="First Name*"
                      className={`contact-field ${
                        errors.firstName && "border border-danger"
                      }`}
                    />
                    {errors.firstName && (
                      <span className="text-danger">{errors.firstName}</span>
                    )}
                  </div>
                  <div className="mb-4">
                    <input
                      type="text"
                      name="lastName"
                      value={contactData.lastName}
                      onChange={handleChange}
                      placeholder="Last Name*"
                      className={`contact-field ${
                        errors.lastName && "border border-danger"
                      }`}
                    />
                    {errors.lastName && (
                      <span className="text-danger">{errors.lastName}</span>
                    )}
                  </div>
                  <div className="mb-4">
                    <input
                      type="text"
                      name="email"
                      value={contactData.email}
                      onChange={handleChange}
                      placeholder="Email Address*"
                      className={`contact-field ${
                        errors.email && "border border-danger"
                      }`}
                    />
                    {errors.email && (
                      <span className="text-danger">{errors.email}</span>
                    )}
                  </div>
                  <div className="mb-4">
                    <input
                      type="number"
                      name="phone"
                      value={contactData.phone}
                      onChange={handleChange}
                      placeholder="Phone No*"
                      className={`contact-field ${
                        errors.phone && "border border-danger"
                      }`}
                    />
                    {errors.phone && (
                      <span className="text-danger">{errors.phone}</span>
                    )}
                  </div>
                  <div className="mb-4">
                    <textarea
                      rows={4}
                      name="message"
                      value={contactData.message}
                      onChange={handleChange}
                      placeholder="How can we help?"
                      className={`contact-field ${
                        errors.message && "border border-danger"
                      }`}
                    />
                    {errors.message && (
                      <span className="text-danger">{errors.message}</span>
                    )}
                  </div>

                  <ReCAPTCHA
                    sitekey="6LeK8rAkAAAAAMiBs0pgYjxJyFakKO6lQLn70b8O"
                    onChange={onCapchaChange}
                    className="mt-4"
                  />

                  <div className="mt-4">
                    <button className="orange-btn px-4"> send message </button>
                  </div>
                </form>
              </div>
              {/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- */}

              <div className="mt-5">
                <h4 className="fw-bold text-dark-blue mb-4">Popular Post</h4>

                <div>
                  {allBlogs?.slice(0, 3).map((blog) => {
                    return (
                      <Link
                        to={`${Config.appUrl}/${blog?.slug.toLowerCase()}`}
                        tabIndex="-1"
                        key={blog.id}
                      >
                        <div className="d-flex align-items-center popular-post mb-3">
                          <div>
                            <img
                              src={`https://www.addontechno.com/admin/assets/front/img/blogs/${blog?.main_image}`}
                              alt="blogImage"
                            />
                          </div>
                          <div className="ms-3">
                            <p className="mb-1 text-orange">
                              {new Date(blog?.updated_at).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )}
                            </p>
                            <p className="mb-0 text-dark">{blog?.title}</p>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>

              <div className="mt-5">
                <h4 className="fw-bold text-dark-blue mb-4">Tags</h4>

                <div className="tags">
                  {tags?.map((tag) => {
                    return (
                      <Link
                        to={`${Config.appUrl}/tag/${tag?.slug.toLowerCase()}`}
                        key={tag?.id}
                      >
                        {" "}
                        {tag?.name}{" "}
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SearchBlog;
