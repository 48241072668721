import React, { useEffect } from 'react'
import { faCheckToSlot, faGear, faHandsClapping } from "@fortawesome/free-solid-svg-icons";

// component
import ProductDetail from '../ProductDetail';

// images
import PASystemImg from '../../assets/images/products/PA-System.jpg'
import { Helmet } from 'react-helmet';
import { faConnectdevelop } from '@fortawesome/free-brands-svg-icons';
import { Config } from '../../Config';

const PASystem = () => {

    useEffect(() => window.scrollTo(0, 0), []);

    const sideTabLinks = [
        {
            title: 'Access Control',
            link: `${Config.appUrl}/other-products/access-control`
        },
        {
            title: 'Digital Signage Solution',
            link: `${Config.appUrl}/other-products/digital-signage-solution`
        },
        {
            title: 'PA System',
            link: `${Config.appUrl}/other-products/pa-system`
        }
    ]

    const productDetails = {
        name: 'PA System',
        image: PASystemImg,
        paragraphs: [
            "Public address systems are electronic devices that can be used to enhance the impact as well as effect of the audio that is being broadcasted using different mediums like mics, speakers, megaphones, amplifiers, etc.",

            "Our range of Public address systems are well tested, offering the customers the exact kind of audio broadcast medium that can effectively reach the crowd. Our systems are known for the quality of audio and the broad span of features it offers."
        ],
        features: [
            {
                title: 'Portability',
                desc: 'Public address systems are electronic devices that can be used to enhance the impact as well as effect of the audio that is being broadcasted using different mediums like mics, speakers, megaphones, amplifiers, etc. Our range of Public address systems are well tested, offering the customers the exact kind of audio broadcast medium that can effectively reach the crowd. Our systems are known for the quality of audio and the broad span of features it offers.',
                icon: faHandsClapping
            },
            {
                title: 'Flexible',
                desc: 'The PA systems we offer are supportive and flexible in nature which enables both outdoor as well as indoor use. The systems are flexible in terms of connectivity, sound coverage, range of speakers, operating power (batteries for outdoor), etc.',
                icon: faCheckToSlot
            },
            {
                title: 'Connectivity',
                desc: 'Our range of PA systems enable easy connectivity that help in connecting systems in different rooms and control sound and range of the audio from a central location while also equip good quality cables that do not disturb the dissemination of sound and its quality.',
                icon: faGear
            },
            {
                title: 'Wired and wireless',
                desc: 'We have both wired as well as wireless systems that can be used according to the need of the situation. While wired connections enable clearer sound and lesser disturbance, wireless systems allow free movement along with being easy to use.',
                icon: faConnectdevelop
            }
        ],
        lastParagraph: <p className='paragraph'>We ensure good quality PA systems that not only broadcast clear and disturbance free audio to the public but also make sure that they are easy to manage for the ones using them.</p>
    }

    const middleLink = { title: "Other Products", link: `${Config.appUrl}/other-products` }

    return (
        <>
            <Helmet>
                <title>Powerful Public Address Systems for Crystal-Clear Sound in Gujarat</title>

                <meta name="description" content="Experience superior audio quality with our reliable public address systems. Explore our advanced PA speaker systems in Vadodara for impactful sound solutions." />
                <meta name='keywords' content='public address systems, pa systems, pa speaker system, pa sound system, public address speaker, public address systems vadodara' />
            </Helmet>
            <ProductDetail sideTabLinks={sideTabLinks} productDetails={productDetails} middleLink={middleLink} />
        </>
    )
}

export default PASystem