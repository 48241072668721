import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faClock, faComment, faDiagramProject, faSearch, faUser } from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import swal from "sweetalert";
import loaderImg from '../../assets/images/loader.gif'
import commentUserImg from '../../assets/images/commentUser.jpg'

import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from 'react-helmet';
import { Config } from "../../Config";

const BlogDetails = () => {

    // state for form data
    const [contactData, setContactData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
    });
    // state for errors messages
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
    });

    const [isVerified, setIsVerified] = useState(false)

    const [loading, setLoading] = useState(false);
    // state for comment Data 
    const [commentsData, setCommentsData] = useState({
        blog_id: "",
        comment: "",
        name: "",
        email: "",
        website: ""
    })
    const [comments, setComments] = useState([])

    // state for blogs
    const [blogs, setBlogs] = useState([])
    const [blogIndex, setBlogIndex] = useState(-1)
    const [blogTags, setBlogTags] = useState([])
    const [tags, setTags] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    console.log(blogs)

    // routing hooks
    const { slug } = useParams()
    const navigate = useNavigate();

    // getting url path
    const { pathname } = useLocation()

    // Integrating api for fetching blogs
    const getBlogsDetail = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${Config.apiUrl}/mainpage`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setBlogs(response.data.blog)
            setTags(response.data.blog_category)

            const bIndex = response.data.blog.findIndex((blog) => blog.slug.toLowerCase() === slug)
            setBlogIndex(bIndex)

            if (bIndex === -1) {
                navigate('*')
            }
            setBlogTags(response.data.blog_category.filter((blogCat) => blogCat.id === response.data.blog[bIndex].bcategory_id))

            const formData = new FormData();

            formData.append('blog_id', response.data.blog[bIndex].id);
            const commResponse = await axios.post(`${Config.apiUrl}/comment-data`, formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            setComments(commResponse.data.comment_data)
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getBlogsDetail();
    }, [pathname])

    document.title = `${blogs[blogIndex]?.title} | Addon Technology`;

    // enquiry form api integration -------------------------------
    // form validations
    const validateForm = () => {
        const newErrors = {};

        if (!contactData.firstName) {
            newErrors.firstName = 'First name is required';
        }

        if (!contactData.lastName) {
            newErrors.lastName = 'Last name is required';
        }

        if (!contactData.email) {
            newErrors.email = 'Email is required';
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(contactData.email)) {
            newErrors.email = 'Invalid email format';
        }

        if (!contactData.phone) {
            newErrors.phone = 'Phone number is required';
        } else if (contactData.phone.length > 10 || contactData.phone.length < 10) {
            newErrors.phone = 'Phone number must be of 10 digits';
        }

        if (!contactData.message) {
            newErrors.message = 'Your Query is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // sending clients query
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (validateForm() && isVerified) {
            setLoading(true)

            try {
                const formData = new FormData();

                formData.append('fname', contactData.firstName);
                formData.append('lname', contactData.lastName);
                formData.append('email', contactData.email);
                formData.append('phone', contactData.phone);
                formData.append('help', contactData.message);

                await axios.post(`${Config.apiUrl}/contact`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })

                swal("Message Sent", "Your Query has been sent successfully", "success");
                setLoading(false)
                setContactData(
                    {
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        message: '',
                    }
                )
            } catch (error) {
                console.log(error)
                setLoading(false)
                swal("Something Went Wrong!", "", "error");
            }
        }
    }

    // set Form Data
    const handleChange = (e) => {
        setContactData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    };
    // -------------------------------------------------------------

    // Comments ----------------------------------------------------

    const changeCommentData = (e) => {
        setCommentsData({ ...commentsData, [e.target.name]: e.target.value, blog_id: blogs[blogIndex]?.id })
    }

    const handleCommentSubmit = async (event) => {
        event.preventDefault();
        if (isVerified) {
            setLoading(true)
            try {
                const formData = new FormData();

                formData.append('blog_id', commentsData.blog_id);
                formData.append('comment', commentsData.comment);
                formData.append('name', commentsData.name);
                formData.append('email', commentsData.email);
                formData.append('website', commentsData.website);

                await axios.post(`${Config.apiUrl}/comment-us`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })

                // console.log(response)

                setCommentsData({
                    blog_id: "",
                    comment: "",
                    name: "",
                    email: "",
                    website: ""
                })
                setLoading(false)
                swal("Your Comment Added", "", "success");
                setIsVerified(false)
            }
            catch (error) {
                console.log(error)
                swal("Something went wrong", "", "error");
            }
        } else {
            swal("Confirm that you are not a robot", "", "warning");
        }
    }

    // google recapcha change
    function onCapchaChange(value) {
        setIsVerified(true);
        setTimeout(() => {
            setIsVerified(false)
        }, 180000)
    }
    // -=-=-=-=-=--=-=-=-=-=-=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-

    // Search blog functionality 
    const handleSearch = (e) => {
        e.preventDefault()
        const serachSlug = searchValue.replaceAll(' ', '+')
        navigate(`/search/${serachSlug}`)
    }

    // google recapcha change
    function onCapchaChange(value) {
        setIsVerified(true);
        setTimeout(() => {
            setIsVerified(false)
        }, 180000)
    }
    console.log(blogs[blogIndex])
    return (
        <>
            {/* adding dynamic description */}
            <Helmet>
                <meta name="description" content={blogs[blogIndex]?.meta_description} />
                <meta name="keywords" content={blogs[blogIndex]?.meta_keywords} />
            </Helmet>

            {/* loading */}
            <div className={`loader ${loading ? 'd-flex' : 'd-none'}`}>
                <img src={loaderImg} alt="loaderImg" />
            </div>

            <section className="page-header py-5">
                <div className="container-box py-4">
                    <div className="text-white">
                        <h1 className="fw-bold display-5 text-capitalize">{blogs[blogIndex]?.title}</h1>
                        <p className="mt-4 header-path-link text-capitalize">
                            <Link to={`${Config.appUrl}/`} className="text-orange">
                                {" "}
                                Home
                            </Link>
                            {
                                blogTags.length > 0 ?
                                    <>
                                        <FontAwesomeIcon
                                            icon={faArrowRight}
                                            className="social-icon mx-md-3 mx-2"
                                        ></FontAwesomeIcon>
                                        <Link
                                            to={`${Config.appUrl}/service/${blogTags[0]?.name.toLowerCase().replaceAll(' ', '-')}`}
                                            className="text-orange">{blogTags[0]?.name}
                                        </Link>
                                    </>
                                    : null
                            }
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                className="social-icon mx-md-3 mx-2"
                            ></FontAwesomeIcon>
                            {blogs[blogIndex]?.title}
                        </p>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container-box py-5">
                    <div className="row justify-content-center ">

                        {/* left section */}
                        <div className="col-lg-8 col-md-7 pe-lg-4">
                            {/* blog image and detail */}
                            <div>
                                <img
                                    src={`https://www.addontechno.com/admin/assets/front/img/blogs/${blogs[blogIndex]?.main_image}`} alt="blogImage" className="w-100" />

                                <div className="blog-record-details mt-4">
                                    <p className="mb-0 d-inline-block pe-3 border-end border-secondary">
                                        <FontAwesomeIcon
                                            icon={faClock}
                                            className="me-2"
                                        ></FontAwesomeIcon>
                                        {new Date(blogs[blogIndex]?.updated_at)
                                            .toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
                                    </p>
                                    <p className="mb-0 d-inline-block px-3 border-end border-secondary">
                                        <FontAwesomeIcon
                                            icon={faUser}
                                            className="me-2"
                                        ></FontAwesomeIcon>
                                        <Link to={`${Config.appUrl}/author/mehul-goswami`} className="text-dark hover-red">Mehul Goswami</Link>
                                    </p>
                                    <p className="mb-0 d-inline-block pe-3 ps-md-3 border-end border-secondary mt-md-0 mt-3">
                                        <FontAwesomeIcon
                                            icon={faDiagramProject}
                                            className="me-2"
                                        ></FontAwesomeIcon>
                                        <Link
                                            to={`${Config.appUrl}/service/${blogTags[0]?.name.toLowerCase().replaceAll(' ', '-')}`}
                                            className="text-dark hover-red">{blogTags[0]?.name}
                                        </Link>
                                    </p>
                                    <p className="mb-0 d-inline-block px-3 mt-md-0 mt-3">
                                        <FontAwesomeIcon
                                            icon={faComment}
                                            className="me-2"
                                        ></FontAwesomeIcon> {comments?.length} Comment
                                    </p>
                                </div>
                            </div>

                            {/* Dynamic Blog Content */}
                            <div className="mb-5 mt-4 blog-dynamic-content"
                                dangerouslySetInnerHTML={{ __html: blogs[blogIndex]?.content }} />

                            {/* tags */}
                            <div>
                                <h4 className="fw-bold text-dark-blue">Tags</h4>
                                <div className="tags">
                                    {
                                        blogTags?.map((tag) => {
                                            return (
                                                <Link to={`${Config.appUrl}/tag/${tag?.slug.toLowerCase()}`} key={tag?.id}> {tag?.name} </Link>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            {/* previous & next buttons */}
                            <div className="d-flex justify-content-between my-5 next-prev-btn border-top pt-3">
                                <div>
                                    <button
                                        className={`border-0 bg-transparent 
                                                ${blogIndex < blogs.length - 1 ? 'd-inline' : 'd-none'}`}
                                        onClick={() => navigate(`/${blogs[blogIndex + 1].slug.toLowerCase()}`)}>
                                        <FontAwesomeIcon
                                            icon={faArrowLeft}
                                            className="me-2"
                                        ></FontAwesomeIcon>
                                        Previous Post
                                    </button>
                                </div>
                                <div className="text-end">
                                    <button
                                        className={`border-0 bg-transparent 
                                                ${blogIndex > 0 ? 'd-inline' : 'd-none'}`}
                                        onClick={() => navigate(`/${blogs[blogIndex - 1].slug.toLowerCase()}`)}>
                                        Next Post
                                        <FontAwesomeIcon
                                            icon={faArrowRight}
                                            className="ms-2"
                                        ></FontAwesomeIcon>
                                    </button>
                                </div>
                            </div>

                            {/* comments */}
                            <div className="pt-4" >
                                <h4 className="fw-bold text-dark-blue">{comments.length} Comment </h4>
                                {
                                    comments?.map((comment, i) => {
                                        return (
                                            <div key={i} className="comment pb-4 border-bottom d-flex justify-content-between mt-5">
                                                <div className="d-flex align-items-start">
                                                    <img src={commentUserImg} alt="commentUserImg" className="comment-img d-md-inline d-none" />
                                                    <div className="ms-md-3">
                                                        <h5 className="fw-bold mb-1">{comment?.name}</h5>
                                                        <p className="mb-2">{comment?.created_at}</p>
                                                        <p>{comment?.comment}</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <a href="#reply-form" className="btn btn-sm px-3 btn-outline-secondary">Reply</a>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            {/* Reply form */}
                            <div className="mt-5" id="reply-form">
                                <h4 className="fw-bold text-dark-blue">Leave a Reply </h4>
                                <form action="" onSubmit={handleCommentSubmit}>
                                    <div className="row">
                                        <div className="col-12 mt-4">
                                            <textarea rows="6"
                                                name="comment"
                                                value={commentsData.comment}
                                                onChange={changeCommentData}
                                                placeholder="Comment"
                                                className="contact-field" required />
                                        </div>

                                        <div className="col-md-6 mt-4">
                                            <input type="text"
                                                name="name"
                                                value={commentsData.name}
                                                onChange={changeCommentData}
                                                placeholder="Name *"
                                                className="contact-field" required />
                                        </div>
                                        <div className="col-md-6 mt-4">
                                            <input type="email"
                                                name="email"
                                                value={commentsData.email}
                                                onChange={changeCommentData}
                                                placeholder="Email *"
                                                className="contact-field" required />
                                        </div>

                                        <div className="col-12 mt-4">
                                            <input type="url"
                                                name="website"
                                                value={commentsData.website}
                                                onChange={changeCommentData}
                                                placeholder="Website"
                                                className="contact-field" />
                                        </div>

                                        <ReCAPTCHA
                                            sitekey="6LeK8rAkAAAAAMiBs0pgYjxJyFakKO6lQLn70b8O"
                                            onChange={onCapchaChange}
                                            className="mt-4"
                                        />

                                        <div className="mt-4">
                                            <button className="orange-btn px-4"> post comment</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {/* right section */}
                        <div className="col-lg-4 col-md-5 mt-md-0 mt-5 pt-md-0 pt-5">
                            <form onSubmit={handleSearch}>
                                <div className="search-bar d-flex justify-content-between">
                                    <input type="text"
                                        value={searchValue}
                                        onChange={(e) => setSearchValue(e.target.value)}
                                        placeholder="Search..."
                                        className="px-3 py-2"
                                        required
                                    />
                                    <button >
                                        <FontAwesomeIcon
                                            icon={faSearch}
                                            className=""
                                        ></FontAwesomeIcon>
                                    </button>
                                </div>
                            </form>

                            {/* enquiry form */}
                            <div className="bg-light-gray mt-5 p-4">
                                <h4 className='fw-bold mb-4 text-dark-blue'>QUICK ENQUIRY FORM</h4>
                                <form action="" onSubmit={handleSubmit}>
                                    <div className='mb-4'>
                                        <input type="text" name="firstName"
                                            value={contactData.firstName}
                                            onChange={handleChange}
                                            placeholder='First Name*'
                                            className={`contact-field ${errors.firstName && 'border border-danger'}`} />
                                        {errors.firstName && <span className='text-danger'>{errors.firstName}</span>}
                                    </div>
                                    <div className='mb-4'>
                                        <input type="text" name="lastName"
                                            value={contactData.lastName}
                                            onChange={handleChange}
                                            placeholder='Last Name*'
                                            className={`contact-field ${errors.lastName && 'border border-danger'}`} />
                                        {errors.lastName && <span className='text-danger'>{errors.lastName}</span>}
                                    </div>
                                    <div className='mb-4'>
                                        <input type="text" name='email'
                                            value={contactData.email}
                                            onChange={handleChange}
                                            placeholder='Email Address*'
                                            className={`contact-field ${errors.email && 'border border-danger'}`} />
                                        {errors.email && <span className='text-danger'>{errors.email}</span>}
                                    </div>
                                    <div className='mb-4'>
                                        <input type="number" name='phone'
                                            value={contactData.phone}
                                            onChange={handleChange}
                                            placeholder='Phone No*'
                                            className={`contact-field ${errors.phone && 'border border-danger'}`} />
                                        {errors.phone && <span className='text-danger'>{errors.phone}</span>}
                                    </div>
                                    <div className='mb-4'>
                                        <textarea rows={4} name='message'
                                            value={contactData.message}
                                            onChange={handleChange}
                                            placeholder='How can we help?'
                                            className={`contact-field ${errors.message && 'border border-danger'}`} />
                                        {errors.message && <span className='text-danger'>{errors.message}</span>}
                                    </div>

                                    <ReCAPTCHA
                                        sitekey="6LeK8rAkAAAAAMiBs0pgYjxJyFakKO6lQLn70b8O"
                                        onChange={onCapchaChange}
                                        className="mt-4"
                                    />

                                    <div className='mt-4'>
                                        <button className='orange-btn px-4'> send message </button>
                                    </div>
                                </form>
                            </div>
                            {/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- */}

                            <div className="mt-5">
                                <h4 className='fw-bold text-dark-blue mb-4'>Popular Post</h4>

                                <div>
                                    {
                                        blogs?.slice(0, 3).map((blog) => {
                                            return (
                                                <Link to={`${Config.appUrl}/${blog?.slug.toLowerCase()}`} tabIndex="-1" key={blog.id}>
                                                    <div className='d-flex align-items-center popular-post mb-3'>
                                                        <div>
                                                            <img src={`https://www.addontechno.com/admin/assets/front/img/blogs/${blog?.main_image}`} alt="blogImage" />
                                                        </div>
                                                        <div className='ms-3'>
                                                            <p className='mb-1 text-orange'>
                                                                {new Date(blog?.updated_at)
                                                                    .toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                                                            </p>
                                                            <p className="mb-0 text-dark">
                                                                {blog?.title}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            )
                                        })
                                    }

                                </div>
                            </div>

                            <div className="mt-5">
                                <h4 className='fw-bold text-dark-blue mb-4'>Tags</h4>

                                <div className="tags">
                                    {
                                        tags?.map((tag) => {
                                            return (
                                                <Link to={`${Config.appUrl}/tag/${tag?.slug.toLowerCase()}`} key={tag?.id}> {tag?.name} </Link>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );


}

export default BlogDetails;
