import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowRight
} from "@fortawesome/free-solid-svg-icons";

// images
import { Helmet } from "react-helmet";
import Img404 from "../../assets/images/404.png"
import { Config } from "../../Config";

const PageNotFound = () => {

    useEffect(() => window.scrollTo(0, 0), []);


    return (
        <>
            <Helmet>
                <title>Page not found - Addon Technology</title>
            </Helmet>

            <section className="page-header py-5">
                <div className="container-box py-4">
                    <div className="text-white">
                        <h1 className="fw-bold display-5">404 Page Not Found</h1>
                        <p className="mt-4 header-path-link">
                            <Link to={`${Config.appUrl}/`} className="text-orange">
                                {" "}
                                Home
                            </Link>
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                className="social-icon me-3"
                            ></FontAwesomeIcon>
                            Error 404 Page Not Found
                        </p>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container-box py-md-5 text-center">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <img src={Img404} className="img-fluid" alt="errorImage" />
                        </div>
                    </div>
                    <p className="my-5 fs-5">Sorry, The Page your are looking for, could not be found.</p>
                    <Link to={`${Config.appUrl}/`} className="orange-btn"> go to home </Link>
                </div>
            </section>
        </>
    );
};

export default PageNotFound;
