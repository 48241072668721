import React, { useEffect, useState } from 'react'
import loaderImg from "../../assets/images/loader.gif"
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Config } from '../../Config';

const PrivacyPolicy = () => {
    const [loading, setLoading] = useState(false);
    useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
    {/* loading */}
         <div className={`loader ${loading ? 'd-flex' : 'd-none'}`}>
                <img src={loaderImg} alt="loaderImage" />
            </div>
            <section className="page-header py-5">
                <div className="container-box py-4">
                    <div className='text-white'>
                        <h1 className='fw-bold display-5'>Privacy Policy</h1>
                        <p className='mt-4 header-path-link'>
                            <Link to={`${Config.appUrl}/`} className="text-orange"> Home</Link>
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                className='social-icon me-3'
                            ></FontAwesomeIcon>
                            Privacy Policy
                        </p>
                    </div>
                </div>
            </section>

            <section >
        <div className="container-box-terms py-md-5 owner-bg top-heading-terms">
            <h3 className='display-5 ' >Privacy Policy</h3>
            
            <p>Hey there, welcome to Addon Technology's corner of the internet! We're all about making your online experience smooth and secure. Your privacy matters to us, and we want to be upfront about how we handle your information. So, here's the lowdown on what we collect and how we use it.</p>

            <span>What We Collect</span>
            <p>When you swing by our site, we automatically pick up some basic info like your IP address, what browser you're using, and maybe even what kind of device you're on. We also use cookies (not the chocolate chip kind, unfortunately!) and similar tech to make our site work better for you.</p>
            <p>If you decide to drop us a line or sign up for our newsletter, we'll ask for things like your name, email, and maybe your phone number, just so we can stay in touch.</p>

            <span className='mb-2'>How We Use It</span>
            <ul className='technical-solutions-terms'>
                <li>We're not in the business of hoarding data – we just want to make your experience with us awesome. So, we use the info we collect to:</li>
                <li>Make our site better and easier to use</li>
                <li>Answer your questions and sort out any issues</li>
                <li>Keep you posted on all the cool stuff happening at Addon</li>
                <li>Understand how you use our site so we can keep improving it</li>
                <li>Keep things secure and sniff out any funny business</li>
            </ul>

            <span>Sharing Is Caring</span>
            <p>Your info is precious, and we treat it that way. We won't go sharing it with random folks, but we might need to pass it on to trusted partners who help us run our site or provide our services. Rest assured, though – they're just as serious about privacy as we are.</p>
            <p>And if the law comes knocking or we need to protect our rights (or yours!), we might have to spill the beans. But we'll only do it if we must</p>

            <span>Your Call</span>
            <p>We're all about giving you control. If you're not vibing with our emails anymore, just hit the unsubscribe button – no hard feelings! And if you want to tweak or delete any of the info, we have on you, just give us a shout.</p>

            <span>Keeping It Safe</span>
            <p>We've got your back when it comes to keeping your info safe and sound. We've got all the latest security measures in place, but like with anything online, there's always a tiny risk. We'll do everything we can to minimize it, though!</p>

            <span>Changes & Chats</span>
            <p>If we ever need to tweak this Privacy Policy, we'll give you a heads-up right here on our site. And if you've got any questions or just fancy a chat, drop us a line at <a href='mailto:sales@addontechno.com' >sales@addontechno.com</a>. We love hearing from you!</p>

           

        </div>
        </section>
    

      
    </>
  )
}

export default PrivacyPolicy

