import React, { useEffect, useState } from 'react'
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faEnvelope, faMapLocation, faPhone } from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";

import swal from 'sweetalert';
import axios from 'axios';
import loaderImg from '../assets/images/loader.gif'
import { Config } from '../Config';

const ProductDetail = ({ sideTabLinks, productDetails, middleLink }) => {

    const [loading, setLoading] = useState(false);
    // state for form data
    const [contactData, setContactData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
    });
    // state for errors messages
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
    });
    const [isVerified, setIsVerified] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // form validations
    const validateForm = () => {
        const newErrors = {};

        if (!contactData.firstName) {
            newErrors.firstName = 'First name is required';
        }

        if (!contactData.lastName) {
            newErrors.lastName = 'Last name is required';
        }

        if (!contactData.email) {
            newErrors.email = 'Email is required';
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(contactData.email)) {
            newErrors.email = 'Invalid email format';
        }

        if (!contactData.phone) {
            newErrors.phone = 'Phone number is required';
        } else if (contactData.phone.length > 10 || contactData.phone.length < 10) {
            newErrors.phone = 'Phone number must be of 10 digits';
        }

        if (!contactData.message) {
            newErrors.message = 'Your Query is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // sending clients query
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (validateForm()) {

            if (isVerified) {
                setLoading(true)
                try {
                    const formData = new FormData();

                    formData.append('fname', contactData.firstName);
                    formData.append('lname', contactData.lastName);
                    formData.append('email', contactData.email);
                    formData.append('phone', contactData.phone);
                    formData.append('help', contactData.message);

                    await axios.post(`${Config.apiUrl}/contact`, formData, {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    })
                    // console.log(response)

                    swal("Message Sent", "Your Query has been sent successfully", "success");
                    setLoading(false)
                    setContactData(
                        {
                            firstName: '',
                            lastName: '',
                            email: '',
                            phone: '',
                            message: '',
                        }
                    )
                    setIsVerified(false)
                } catch (error) {
                    console.log(error)
                    setLoading(false)
                    swal("Something Went Wrong!", "", "error");
                }
            } else {
                swal("Confirm that you are not a robot", "", "warning");
            }

        }
    }

    // set Form Data
    const handleChange = (e) => {
        setContactData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    };


    // google recapcha change
    function onCapchaChange(value) {
        setIsVerified(true);
        setTimeout(() => {
            setIsVerified(false)
        }, 180000)
    }

    return (
        <>

            {/* loading */}
            <div className={`loader ${loading ? 'd-flex' : 'd-none'}`}>
                <img src={loaderImg} alt="loaderImg" />
            </div>

            <section className="page-header py-5">
                <div className="container-box py-4">
                    <div className="text-white">
                        <h1 className="fw-bold display-5">{productDetails.name}</h1>
                        <p className="mt-4 header-path-link">
                            <Link to={`${Config.appUrl}/`} className="text-orange">
                                {" "}
                                Home
                            </Link>
                            {
                                middleLink ?
                                    <>
                                        <FontAwesomeIcon
                                            icon={faArrowRight}
                                            className="mx-md-3 mx-2"
                                        ></FontAwesomeIcon>
                                        <Link to={middleLink.link} className="text-orange">
                                            {" "}
                                            {middleLink.title}
                                        </Link>
                                    </> : null
                            }
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                className="mx-md-3 mx-2"
                            ></FontAwesomeIcon>
                            {productDetails.name}
                        </p>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container-box py-md-5">
                    <div className="row align-items-lg-start align-items-center flex-lg-row flex-column-reverse">
                        <div className="col-lg-4 col-md-10 mt-lg-0 mt-5">
                            <div className='left-product-links'>
                                {
                                    sideTabLinks.map((sideLink, index) =>
                                        <NavLink to={sideLink.link} key={index}>{sideLink.title}</NavLink>)
                                }
                            </div>

                            <div className='request-brochures mt-5'>
                                <Link to={`${Config.appUrl}/contact-us`}>
                                    Request Brochures

                                    <FontAwesomeIcon
                                        icon={faMapLocation}
                                    ></FontAwesomeIcon>
                                </Link>
                            </div>

                            <div className='contact-details-box mt-5'>
                                <h4 className='fw-bold'>Have any Questions?</h4>
                                <p className='my-4'> Please feel free to contact us. We will get back to you within 1-2 business days. Or you can call us now. </p>

                                <div>
                                    <p>
                                        <FontAwesomeIcon
                                            icon={faPhone}
                                            className="text-white me-3"
                                        ></FontAwesomeIcon>
                                        <strong>Call Us : </strong>
                                        <Link to="tel:+91-98244-18222" className='text-white'>+91-98244-18222</Link>

                                    </p>
                                    <p className='mb-0'>
                                        <FontAwesomeIcon
                                            icon={faEnvelope}
                                            className="text-white me-3"
                                        ></FontAwesomeIcon>
                                        <strong>Mail Us : </strong>
                                        <Link to="mailto:sales@addontechno.com" className='text-white'>sales@addontechno.com</Link>
                                    </p>
                                </div>
                            </div>

                            <div className="bg-light-gray mt-5 p-4">
                                <h4 className='fw-bold mb-4 text-dark-blue'>QUICK ENQUIRY FORM</h4>
                                <form action="" onSubmit={handleSubmit}>
                                    <div className='mb-4'>
                                        <input type="text" name="firstName"
                                            value={contactData.firstName}
                                            onChange={handleChange}
                                            placeholder='First Name*'
                                            className={`contact-field ${errors.firstName && 'border border-danger'}`} />
                                        {errors.firstName && <span className='text-danger'>{errors.firstName}</span>}
                                    </div>
                                    <div className='mb-4'>
                                        <input type="text" name="lastName"
                                            value={contactData.lastName}
                                            onChange={handleChange}
                                            placeholder='Last Name*'
                                            className={`contact-field ${errors.lastName && 'border border-danger'}`} />
                                        {errors.lastName && <span className='text-danger'>{errors.lastName}</span>}
                                    </div>
                                    <div className='mb-4'>
                                        <input type="text" name='email'
                                            value={contactData.email}
                                            onChange={handleChange}
                                            placeholder='Email Address*'
                                            className={`contact-field ${errors.email && 'border border-danger'}`} />
                                        {errors.email && <span className='text-danger'>{errors.email}</span>}
                                    </div>
                                    <div className='mb-4'>
                                        <input type="number" name='phone'
                                            value={contactData.phone}
                                            onChange={handleChange}
                                            placeholder='Phone No*'
                                            className={`contact-field ${errors.phone && 'border border-danger'}`} />
                                        {errors.phone && <span className='text-danger'>{errors.phone}</span>}
                                    </div>
                                    <div className='mb-4'>
                                        <textarea rows={4} name='message'
                                            value={contactData.message}
                                            onChange={handleChange}
                                            placeholder='How can we help?'
                                            className={`contact-field ${errors.message && 'border border-danger'}`} />
                                        {errors.message && <span className='text-danger'>{errors.message}</span>}
                                    </div>

                                    <ReCAPTCHA
                                        sitekey="6LeK8rAkAAAAAMiBs0pgYjxJyFakKO6lQLn70b8O"
                                        onChange={onCapchaChange}
                                        className="mt-4"
                                    />

                                    <div className='mt-4'>
                                        <button className='orange-btn px-4'> send message </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-10 ps-lg-3">
                            <div>
                                <img src={productDetails.image} alt={productDetails.name} className='w-100 product-detail-img' />
                            </div>

                            <div className='mt-4'>
                                <h2 className='fw-bold text-dark-blue'> {productDetails.name} </h2>
                                {
                                    productDetails.paragraphs.map((paragraph, index) => {
                                        return (
                                            <p key={index}
                                                className={`paragraph mt-4 
                                                ${(productDetails.paragraphs.length - 1) === index && 'mb-0'}`}>
                                                {paragraph}
                                            </p>
                                        )
                                    })
                                }
                            </div>

                            <div className="border-bottom pb-4">
                                <div className="row pb-2">

                                    {
                                        productDetails.features.map((feature, index) => {
                                            return (
                                                <div className="col-md-6 mt-4" key={index}>
                                                    <div className="d-flex align-items-start">
                                                        <div className="company-icon p-3 border rounded">
                                                            <FontAwesomeIcon
                                                                icon={feature.icon}
                                                                className="fs-2 text-orange"
                                                            ></FontAwesomeIcon>
                                                        </div>
                                                        <div className="ps-4">
                                                            <h5 className="text-dark-blue fw-bold">
                                                                {feature.title}
                                                            </h5>
                                                            <p className="paragraph mb-0">
                                                                {feature.desc}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className='mt-4'>
                                {productDetails.lastParagraph}
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProductDetail