import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faComments, faLink } from "@fortawesome/free-solid-svg-icons";

// loader Image
import loaderImg from '../../assets/images/loader.gif'
import { Helmet } from "react-helmet";
import { Config } from "../../Config";

const Blogs = () => {

  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  // Integrating api for fetching blogs
  const getBlogs = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${Config.apiUrl}/mainpage`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        }
      });

      const updatedBlogs = response.data.blog.map((blog) => {
        blog.totalComments = response.data.comment_count.filter((bcomment) => blog.id === bcomment.blog_id).length;
        return blog
      })
      setBlogs(updatedBlogs)

      setLoading(false)

    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    getBlogs();
  }, [])

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>

      <Helmet>
        <title>Addon Techno's Blog on CCTV camera, Fire alarm and VESDA systems</title>

        <meta name="description" content="Get ready to expand your security tech horizons with Addon Techno's blog. Gain valuable insights, stay informed about emerging security technologies." />
      </Helmet>

      {/* loading */}
      <div className={`loader ${loading ? 'd-flex' : 'd-none'}`}>
        <img src={loaderImg} alt="loaderImage" />
      </div>

      <section className="page-header py-5">
        <div className="container-box py-4">
          <div className="text-white">
            <h1 className="fw-bold display-5">Blogs</h1>
            <p className="mt-4 header-path-link">
              <Link to={`${Config.appUrl}`} className="text-orange">
                {" "}
                Home
              </Link>
              <FontAwesomeIcon
                icon={faArrowRight}
                className="social-icon me-3"
              ></FontAwesomeIcon>
              Blogs
            </p>
          </div>
        </div>
      </section>

      {/* Blogs list */}

      <section className="py-md-5 bg-white">
        <div className="container-box pb-5">
          <div className="row ">
            {
              blogs?.map((blog) => {
                return (
                  <div key={blog?.id} className="col-lg-4 col-sm-6 mt-5" aria-hidden="true">
                    <Link to={`${Config.appUrl}/${blog?.slug.toLowerCase()}`} tabIndex="-1" className="text-dark">
                      <div className="blog-card bg-light-gray">
                        <div className="top-img bg-white border border-bottom-0">
                          <img src={`https://www.addontechno.com/admin/assets/front/img/blogs/${blog?.main_image}`} alt="blogImage" />
                          <div> 
                            <FontAwesomeIcon
                              icon={faLink}
                              className="text-orange link-icon"
                            ></FontAwesomeIcon>
                          </div>
                        </div>
                        <div className="border">
                          <div className="row px-3 pt-3">
                            <p className="col-6 pe-0 border-end border-secondary blog-date">
                              By <Link to={`${Config.appUrl}/author/mehul-goswami`} tabIndex="-1" className="text-dark hover-red">Mehul Goswami</Link>
                            </p>
                            <p className="col-6 ps-md-4 ps-0 text-end blog-date">
                              {new Date(blog?.updated_at)
                                .toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                            </p>
                          </div>

                          <div className="px-3 blog-card-content">
                            <h5 className="fw-bold text-dark-blue">
                              {blog?.title}
                            </h5>
                            <p className="blog-desc">
                              {blog?.content?.replace(/<[^>]+>/g, '').slice(0, 140)}
                              {blog?.content?.replace(/<[^>]+>/g, '').length > 140 && '...'}
                            </p>
                          </div>

                          <div className="d-flex border-top fw-500">
                            <div className="col-6 border-end ps-3 py-2">
                                Read More
                            </div>
                            <div className="col-6 pe-3 py-2 text-end">
                              <FontAwesomeIcon
                                icon={faComments}
                                className="text-secondary star-icon"
                              ></FontAwesomeIcon>{" "}
                              {blog?.totalComments} Comments
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogs;
